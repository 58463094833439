import React from "react";
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontBox,
    FremontButton,
    FremontDatePicker,
    FremontInput,
    FremontToggle,
    requiredLabel
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const VendorBuildInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <FremontBox label="ILA Site Survey & FOC (Fiber Optic Characterization):"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isILASiteSurveyComplete] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="ILA Site Survey & FOC - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.ilaSiteSurveyCompletionDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="Terminal FOC:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isTerminalFOCComplete] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="Terminal FOC - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.terminalFOCCompletionDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="System Commissioning & Testing:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isSystemCommissioningComplete] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="System Commissioning & Testing - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.systemCommissioningCompletionDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="Mutual Test:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isMutualTestComplete] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="Mutual Test - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.mutualTestCompletionDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="System Handover MCM:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.systemHandoverMcm]}</div>
                    </div>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const VendorBuildInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Vendor Build
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        stretch
                        errorText={props.errorTexts.isILASiteSurveyComplete}
                        label={requiredLabel("ILA Site Survey & FOC (Fiber Optic Characterization)")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isILASiteSurveyComplete}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isILASiteSurveyComplete)}
                            checked={
                                props.updatedOrder.opticalMetaMap[
                                    Constants.ATTRIBUTES.isILASiteSurveyComplete
                                ]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("ILA Site Survey & FOC - Actual Completion Date:")}
                        errorText={props.errorTexts.ilaSiteSurveyCompletionDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.ilaSiteSurveyCompletionDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.ilaSiteSurveyCompletionDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.ilaSiteSurveyCompletionDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        stretch
                        errorText={props.errorTexts.isTerminalFOCComplete}
                        label={requiredLabel("Terminal FOC")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isTerminalFOCComplete}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isTerminalFOCComplete)}
                            checked={
                                props.updatedOrder.opticalMetaMap[
                                    Constants.ATTRIBUTES.isTerminalFOCComplete
                                ]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Terminal FOC - Actual Completion Date:")}
                        errorText={props.errorTexts.terminalFOCCompletionDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.terminalFOCCompletionDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.terminalFOCCompletionDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.terminalFOCCompletionDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        stretch
                        errorText={props.errorTexts.isSystemCommissioningComplete}
                        label={requiredLabel("System Commissioning & Testing")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isSystemCommissioningComplete}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isSystemCommissioningComplete)}
                            checked={
                                props.updatedOrder.opticalMetaMap[
                                    Constants.ATTRIBUTES.isSystemCommissioningComplete
                                ]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("System Commissioning & Testing - Actual Completion Date:")}
                        errorText={props.errorTexts.systemCommissioningCompletionDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.systemCommissioningCompletionDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.systemCommissioningCompletionDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.systemCommissioningCompletionDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        stretch
                        errorText={props.errorTexts.isMutualTestComplete}
                        label={requiredLabel("Mutual Test")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isMutualTestComplete}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isMutualTestComplete)}
                            checked={
                                props.updatedOrder.opticalMetaMap[
                                    Constants.ATTRIBUTES.isMutualTestComplete
                                ]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Mutual Test - Actual Completion Date:")}
                        errorText={props.errorTexts.mutualTestCompletionDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.mutualTestCompletionDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.mutualTestCompletionDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.mutualTestCompletionDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("System Handover MCM:")}
                        errorText={props.errorTexts.systemHandoverMcm}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.systemHandoverMcm}
                            disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.systemHandoverMcm)}
                            value={props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.systemHandoverMcm]}
                            onInput={props.handleStageInputChange}
                            placeholder="Enter System Handover MCM"
                        />
                    </FormField>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);
