import React from "react";
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontBox,
    FremontButton,
    FremontDatePicker,
    FremontInput,
    FremontToggle,
    requiredLabel
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const AwsProvisioningInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    {Constants.PROJECT_TYPES.longHaulNewSpan === props.order.projectType && (
                        <React.Fragment>
                            <div>
                                <FremontBox label="Management TOR Switch Build:"/>
                                <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isManagementTORComplete] ===
                                Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                                </div>
                            </div>
                            <div>
                                <FremontBox label="Management TOR Switch Build - Actual Completion Date:"/>
                                <div>{props.order.opticalMetaMap[
                                    Constants.ATTRIBUTES.managementTORCompletionDate
                                ]}
                                </div>
                            </div>
                            <div>
                                <FremontBox label="Optical Devices Provisioning:"/>
                                <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.areOpticalDevicesProvisioned] ===
                                Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                                </div>
                            </div>
                            <div>
                                <FremontBox label="Optical Devices Provisioning - Actual Completion Date:"/>
                                <div>{props.order.opticalMetaMap[
                                    Constants.ATTRIBUTES.opticalDevicesProvisioningCompletionDate
                                ]}
                                </div>
                            </div>
                            <div>
                                <FremontBox label="Optical Devices In Operational State:"/>
                                <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.areOpticalDevicesOperational] ===
                                Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                                </div>
                            </div>
                            <div>
                                <FremontBox label="Optical Devices In Operational State - Actual Completion Date:"/>
                                <div>{props.order.opticalMetaMap[
                                    Constants.ATTRIBUTES.opticalDevicesOperationalCompletionDate
                                ]}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div>
                        <FremontBox label="Ops Checklist MCM:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.opsChecklistMcm]}</div>
                    </div>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const AwsProvisioningInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Aws Provisioning
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    {Constants.PROJECT_TYPES.longHaulNewSpan === props.updatedOrder.projectType && (
                        <React.Fragment>
                            <FormField
                                stretch
                                errorText={props.errorTexts.isManagementTORComplete}
                                label={requiredLabel("Management TOR Switch Build")}
                            >
                                <FremontToggle
                                    id={Constants.ATTRIBUTES.isManagementTORComplete}
                                    disabled={props.isUpdateStageInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.isManagementTORComplete)}
                                    checked={
                                        props.updatedOrder.opticalMetaMap[
                                            Constants.ATTRIBUTES.isManagementTORComplete
                                        ]
                                        === Constants.TRUE_STRING
                                    }
                                    onChange={props.handleStageInputChange}
                                    description="Toggle to track completion"
                                />
                            </FormField>
                            <FormField
                                label={requiredLabel("Management TOR Switch Build - Actual Completion Date:")}
                                errorText={props.errorTexts.managementTORCompletionDate}
                                stretch
                            >
                                <FremontDatePicker
                                    id={Constants.ATTRIBUTES.managementTORCompletionDate}
                                    value={props.updatedOrder
                                        .opticalMetaMap[Constants.ATTRIBUTES.managementTORCompletionDate] || ""}
                                    placeholder={Constants.DATE_PICKER_FORMAT}
                                    disabled={props.isUpdateInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.managementTORCompletionDate)}
                                    onInput={props.handleStageInputChange}
                                    onChange={props.handleStageInputChange}
                                />
                            </FormField>
                            <FormField
                                stretch
                                errorText={props.errorTexts.areOpticalDevicesProvisioned}
                                label={requiredLabel("Optical Devices Provisioning")}
                            >
                                <FremontToggle
                                    id={Constants.ATTRIBUTES.areOpticalDevicesProvisioned}
                                    disabled={props.isUpdateStageInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.areOpticalDevicesProvisioned)}
                                    checked={
                                        props.updatedOrder.opticalMetaMap[
                                            Constants.ATTRIBUTES.areOpticalDevicesProvisioned
                                        ]
                                        === Constants.TRUE_STRING
                                    }
                                    onChange={props.handleStageInputChange}
                                    description="Toggle to track completion"
                                />
                            </FormField>
                            <FormField
                                label={requiredLabel("Optical Devices Provisioning - Actual Completion Date:")}
                                errorText={props.errorTexts.opticalDevicesProvisioningCompletionDate}
                                stretch
                            >
                                <FremontDatePicker
                                    id={Constants.ATTRIBUTES.opticalDevicesProvisioningCompletionDate}
                                    value={props.updatedOrder.opticalMetaMap[
                                        Constants.ATTRIBUTES.opticalDevicesProvisioningCompletionDate
                                    ] || ""}
                                    placeholder={Constants.DATE_PICKER_FORMAT}
                                    disabled={props.isUpdateInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.opticalDevicesProvisioningCompletionDate)}
                                    onInput={props.handleStageInputChange}
                                    onChange={props.handleStageInputChange}
                                />
                            </FormField>
                            <FormField
                                stretch
                                errorText={props.errorTexts.areOpticalDevicesOperational}
                                label={requiredLabel("Optical Devices In Operational State")}
                            >
                                <FremontToggle
                                    id={Constants.ATTRIBUTES.areOpticalDevicesOperational}
                                    disabled={props.isUpdateStageInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.areOpticalDevicesOperational)}
                                    checked={
                                        props.updatedOrder.opticalMetaMap[
                                            Constants.ATTRIBUTES.areOpticalDevicesOperational
                                        ]
                                        === Constants.TRUE_STRING
                                    }
                                    onChange={props.handleStageInputChange}
                                    description="Toggle to track completion"
                                />
                            </FormField>
                            <FormField
                                label={requiredLabel("Optical Devices In Operational State - Actual Completion Date:")}
                                errorText={props.errorTexts.opticalDevicesOperationalCompletionDate}
                                stretch
                            >
                                <FremontDatePicker
                                    id={Constants.ATTRIBUTES.opticalDevicesOperationalCompletionDate}
                                    value={props.updatedOrder.opticalMetaMap[
                                        Constants.ATTRIBUTES.opticalDevicesOperationalCompletionDate
                                    ] || ""}
                                    placeholder={Constants.DATE_PICKER_FORMAT}
                                    disabled={props.isUpdateInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.opticalDevicesOperationalCompletionDate)}
                                    onInput={props.handleStageInputChange}
                                    onChange={props.handleStageInputChange}
                                />
                            </FormField>
                        </React.Fragment>
                    )}
                    <FormField
                        label={requiredLabel("Ops Checklist MCM:")}
                        errorText={props.errorTexts.opsChecklistMcm}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.opsChecklistMcm}
                            disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.opsChecklistMcm)}
                            value={props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.opsChecklistMcm]}
                            onInput={props.handleStageInputChange}
                            placeholder="Enter Ops Checklist MCM"
                        />
                    </FormField>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);
