import Constants from "../utils/Constants";
import HelperFunctions from "../common/HelperFunctions";

export default class LinkHierarchyHelper {
    static getEmptyLinkMap = () => Object.assign(
        {},
        ...Object.keys(HelperFunctions.getLinkHierarchyOrder())
            .map(linkType => ({
                [LinkHierarchyHelper.getReadableLinkTypeFromTypeIdAndStage(linkType)]: []
            }))
    );

    static isLinkMapEmpty = linksInLinkTypeMap => Object.values(linksInLinkTypeMap).every(links => links.length === 0)

    static fetchLinkHierarchy = async (
        linkServiceBackendClient, linksInLinkTypeMap, link
    ) => {
        const linkHierarchyResponse = await linkServiceBackendClient.getLinkHierarchy(
            Constants.LINK_INSTANCE_ID_PATTERN + link.instanceId
        );

        LinkHierarchyHelper.addLinksToLinkTypeMap(linkHierarchyResponse.Links, linksInLinkTypeMap);

        const routerToRouterLinksWithClientPorts =
             LinkHierarchyHelper.addClientPortsToRouterToRouterLinks(linksInLinkTypeMap);

        return {
            ...linksInLinkTypeMap,
            [Constants.LINK_TYPES.routerToRouter]: routerToRouterLinksWithClientPorts
        };
    }

    static addClientPortsToRouterToRouterLinks = (linksInLinkTypeMap) => {
        const routerToRouterLinks = linksInLinkTypeMap[Constants.LINK_TYPES.routerToRouter];

        return routerToRouterLinks.map((link) => {
            // Client ports cannot be shown if both ends are not preset
            if (!link.aEndPort || !link.bEndPort) {
                return link;
            }

            // Filter out links not in the current Router-to-Router hierarchy
            const consumesLinkIds = LinkHierarchyHelper.getAllConsumesLinks(link, linksInLinkTypeMap);
            const consumesLinkMap = {};
            Object.entries(linksInLinkTypeMap).forEach(([linkType, links]) => {
                consumesLinkMap[linkType] = links.filter(l => consumesLinkIds.has(l.instanceId));
            });

            let [aEndDwdmLinkId, aEndClientPort] =
                LinkHierarchyHelper.getRouterToDwdmInfo(link.aEndPort, consumesLinkMap);
            let [bEndDwdmLinkId, bEndClientPort] =
                LinkHierarchyHelper.getRouterToDwdmInfo(link.bEndPort, consumesLinkMap);

            // Router-to-DWDM links are not present, so fallback to Encryption-to-DWDM links
            if (!aEndClientPort && !bEndClientPort) {
                [aEndDwdmLinkId, aEndClientPort]
                    = LinkHierarchyHelper.getEncryptionToDwdmInfo(link.aEndPort, consumesLinkMap);
                [bEndDwdmLinkId, bEndClientPort]
                    = LinkHierarchyHelper.getEncryptionToDwdmInfo(link.bEndPort, consumesLinkMap);
            }

            return {
                ...link,
                aEndDwdmLinkId,
                aEndClientPort,
                bEndDwdmLinkId,
                bEndClientPort
            };
        });
    };

    /**
     * @param fabricInterface string to search on
     * @param consumesLinkMap map of all link types in the current Router-to-Router hierarchy
     * @returns {[string, string]} [instanceId, clientPort]
     */
    static getRouterToDwdmInfo = (fabricInterface, consumesLinkMap) => {
        const routerToDwdm = consumesLinkMap[Constants.LINK_TYPES.routerToDWDM];
        const matchedLinkFromAEnd = routerToDwdm.find(link => link.aEndPort === fabricInterface);
        if (matchedLinkFromAEnd) {
            return [matchedLinkFromAEnd.instanceId, matchedLinkFromAEnd.bEndPort];
        }

        const matchedLinkFromBEnd = routerToDwdm.find(link => link.bEndPort === fabricInterface);

        return !matchedLinkFromBEnd ?
            [null, null] :
            [matchedLinkFromBEnd.instanceId, matchedLinkFromBEnd.aEndPort];
    };

    /**
     * Searches through Router-to-Encryption links in the hierarchy.
     * Then, searches for Encryption-to-DWDM links that share a device name with the found link.
     * @param {string} fabricInterface Router-to-Router A/Z interface
     * @param consumesLinkMap Map of link types in the hierarchy
     * @returns {[undefined,undefined]|[string, string]|} [instanceId, clientPort]
     */
    static getEncryptionToDwdmInfo = (fabricInterface, consumesLinkMap) => {
        const routerToEncryption = consumesLinkMap[Constants.LINK_TYPES.routerToEncryption];
        const encryptionToDwdm = consumesLinkMap[Constants.LINK_TYPES.encryptionToDWDM];
        try {
            const [, routerToEncryptionInterface]
                = LinkHierarchyHelper.getOppositeInterface(fabricInterface, routerToEncryption);

            /**
             * Search between Router-to-Encryption and Encryption-to-DWDM on device name,
             * not full interface name since it is not a direct connection
             */
            const deviceName = LinkHierarchyHelper.getDeviceName(routerToEncryptionInterface);
            return LinkHierarchyHelper.getOppositeDevice(deviceName, encryptionToDwdm);
        } catch (error) {
            console.error(`Error extracting encryption-to-DWDM client ports: ${error}`);
            return [undefined, undefined];
        }
    }

    /**
     * Returns the device name for the link interface
     * @param linkInterface formatted interface using the convention: <deviceName>_<linkInterface>
     * @returns {string | undefined} the device name, or the full interface if not found
     */
    static getDeviceName = linkInterface =>
        (linkInterface?.includes("_") ? linkInterface.split("_").shift() : linkInterface)

    static getOppositeInterface = (port, links) => {
        if (!port) return [undefined, undefined];
        const match = links.find(link => link.aEndPort === port || link.bEndPort === port);
        if (!match) return [undefined, undefined];
        return [
            match.instanceId,
            match.aEndPort === port ? match.bEndPort : match.aEndPort
        ];
    }

    static getOppositeDevice = (deviceName, links) => {
        if (!deviceName) return [undefined, undefined];
        const match = links.find(link =>
            link.aEndPort?.startsWith(deviceName) || link.bEndPort?.startsWith(deviceName));
        if (!match) return [undefined, undefined];
        return [
            match.instanceId,
            match.aEndPort?.includes(deviceName) ? match.bEndPort : match.aEndPort
        ];
    }

    // TODO add empty links should display error button
    static addLinksToLinkTypeMap = (linksToAdd, linksInLinkTypeMap) => {
        linksToAdd.forEach((link) => {
            if (!linksInLinkTypeMap[link.readableLinkType]) {
                Object.assign(linksInLinkTypeMap, { [link.readableLinkType]: [] });
            }
            // Only insert the link if it does not already exist in the list
            if (!linksInLinkTypeMap[link.readableLinkType].some(
                existingLink => existingLink.instanceId === link.instanceId
            )) {
                linksInLinkTypeMap[link.readableLinkType].push(link);
            }
        });
    }

    static getAllConsumesLinks = (routerToRouter, linksInLinkTypeMap) => {
        const linkIds = new Set();

        const getNestedHierarchyIds = (link) => {
            if (!link || linkIds.has(link.instanceId)) return;
            linkIds.add(link.instanceId);
            const consumes = LinkHierarchyHelper.getConsumesLinkIds(link);
            Object.values(linksInLinkTypeMap).forEach((links) => {
                links.filter(l => consumes.has(l.instanceId)).map(getNestedHierarchyIds);
            });
        };

        getNestedHierarchyIds(routerToRouter);
        return linkIds;
    }

    static getConsumesLinkIds = link =>
        LinkHierarchyHelper.parseLinkIdsFromAttribute(link, Constants.CONSUMPTION_ATTRIBUTES.consumesList)

    static parseLinkIdsFromAttribute = (link, attributeKeyToFind) => {
        const ids = [];
        const matchingAttribute = link.attributesToDisplay.find(attribute => attribute.key === attributeKeyToFind);
        if (matchingAttribute) {
            const links = JSON.parse(matchingAttribute.value)
                .map(linkId => HelperFunctions.getIdentifierFromLinkInstance(linkId));
            ids.push(...links);
        }
        return new Set(ids);
    }

    static getReadableLinkTypeFromTypeIdAndStage = (linkType) => {
        let environmentKey = Constants.STAGES.alpha;
        if (HelperFunctions.isDevelopmentStack()) {
            environmentKey = Constants.STAGES.alpha;
        } else if (HelperFunctions.isGamma()) {
            environmentKey = Constants.STAGES.gamma;
        } else if (HelperFunctions.isProd()) {
            environmentKey = Constants.STAGES.prod;
        }

        // Return the readable link type or the ID if we do not have a value in the hardcoded map
        return Constants.READABLE_LINK_TYPE_MAP[environmentKey][linkType] || linkType;
    }

    static getTypeIdFromLink(linkType) {
        let environmentKey;
        if (HelperFunctions.isDevelopmentStack()) {
            environmentKey = Constants.STAGES.alpha;
        } else if (HelperFunctions.isGamma()) {
            environmentKey = Constants.STAGES.gamma;
        } else if (HelperFunctions.isProd()) {
            environmentKey = Constants.STAGES.prod;
        } else {
            environmentKey = Constants.STAGES.alpha; // Default to alpha if no match
        }

        const environmentMap = Constants.READABLE_LINK_TYPE_MAP[environmentKey] || {};
        const flippedMap = Object.entries(environmentMap).reduce((acc, [key, value]) => {
            acc[value] = key;
            return acc;
        }, {});

        return flippedMap[linkType] || linkType;
    }
}