import React from "react";
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontBox,
    FremontButton,
    FremontCheckbox,
    FremontInput,
    requiredLabel
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const CablingOrderInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <FremontBox label="Optical client cut-sheet shared with PIC/ID/TIPM for cabling:"/>
                        <div>
                            {props.order.opticalMetaMap[Constants.ATTRIBUTES.isOpticalClientCutsheetSharedForCabling] &&
                            props.order.opticalMetaMap[Constants.ATTRIBUTES.isOpticalClientCutsheetSharedForCabling]
                            === Constants.TRUE_STRING ? Constants.YES_NO.yes : Constants.YES_NO.no}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="Optical Client Cutsheet MCM:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.opticalClientCutsheetMcm]}</div>
                    </div>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const CablingOrderInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Cabling Order
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        label={requiredLabel("Optical client cut-sheet shared with PIC/ID/TIPM for cabling:")}
                    >
                        <FremontCheckbox
                            id={Constants.ATTRIBUTES.isOpticalClientCutsheetSharedForCabling}
                            checked={
                                props.updatedOrder.opticalMetaMap[
                                    Constants.ATTRIBUTES.isOpticalClientCutsheetSharedForCabling
                                ]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            disabled={props.isUpdateStageInProgress}
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Optical Client Cutsheet MCM:")}
                        errorText={props.errorTexts.opticalClientCutsheetMcm}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.opticalClientCutsheetMcm}
                            disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.opticalClientCutsheetMcm)}
                            value={props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.opticalClientCutsheetMcm]}
                            onInput={props.handleStageInputChange}
                            placeholder="Enter Optical Client Cutsheet MCM"
                        />
                    </FormField>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);
