import React from "react";
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontBox,
    FremontButton,
    FremontInput,
    requiredLabel
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const OpticalPrepWorkInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    {[Constants.PROJECT_TYPES.metroNewSpan, Constants.PROJECT_TYPES.longHaulNewSpan]
                        .includes(props.order.projectType) &&
                        <div>
                            <FremontBox label="MGMT/Trunk Cutsheet MCM:"/>
                            <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.mgmtCutsheetMcm]}</div>
                        </div>
                    }
                    {[Constants.PROJECT_TYPES.longHaulNewSpan, Constants.PROJECT_TYPES.trunkScaling]
                        .includes(props.order.projectType) &&
                        <div>
                            <FremontBox label="Rack Cutsheet MCM:"/>
                            <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.rackCutsheetMcm]}</div>
                        </div>
                    }
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const OpticalPrepWorkInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Optical NE Prep Work
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    {[Constants.PROJECT_TYPES.metroNewSpan, Constants.PROJECT_TYPES.longHaulNewSpan]
                        .includes(props.updatedOrder.projectType) &&
                        <FormField
                            label={requiredLabel("MGMT/Trunk Cutsheet MCM:")}
                            errorText={props.errorTexts.mgmtCutsheetMcm}
                        >
                            <FremontInput
                                id={Constants.ATTRIBUTES.mgmtCutsheetMcm}
                                disabled={props.isUpdateStageInProgress
                                    || props.disabledFieldsList
                                        .includes(Constants.ATTRIBUTES.mgmtCutsheetMcm)}
                                value={props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.mgmtCutsheetMcm]}
                                onInput={props.handleStageInputChange}
                                placeholder="Enter MGMT/Trunk Cutsheet MCM"
                            />
                        </FormField>
                    }
                    {[Constants.PROJECT_TYPES.longHaulNewSpan, Constants.PROJECT_TYPES.trunkScaling]
                        .includes(props.updatedOrder.projectType) &&
                        <FormField
                            label={requiredLabel("Rack Cutsheet MCM:")}
                            errorText={props.errorTexts.rackCutsheetMcm}
                        >
                            <FremontInput
                                id={Constants.ATTRIBUTES.rackCutsheetMcm}
                                disabled={props.isUpdateStageInProgress
                                    || props.disabledFieldsList
                                        .includes(Constants.ATTRIBUTES.rackCutsheetMcm)}
                                value={props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.rackCutsheetMcm]}
                                onInput={props.handleStageInputChange}
                                placeholder="Enter Rack Cutsheet MCM"
                            />
                        </FormField>
                    }
                </ColumnLayout>
            </div>
        </Container>
    </div>
);
