import React from "react";
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontBox,
    FremontButton,
    FremontDatePicker,
    FremontToggle,
    requiredLabel
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const TerminalHardwareInstalledInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <FremontBox label="Terminal RSPC Approved:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isTerminalRSPCApproved] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="Terminal RSPC Approved - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.terminalRSPCApprovedDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="Terminal Hardware PO Created:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isTerminalHardwarePOCreated] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="Terminal Hardware PO Created - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.terminalHardwarePOCreatedDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="Terminal Hardware Delivered:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isTerminalHardwareDelivered] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="Terminal Hardware Delivered - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.terminalHardwareDeliveredDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="Terminal Hardware Installed:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isTerminalHardwareInstalled] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="Terminal Hardware Installed - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.terminalHardwareInstalledDate]}</div>
                    </div>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const TerminalHardwareInstalledInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Terminal Hardware Installed
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        stretch
                        errorText={props.errorTexts.isTerminalRSPCApproved}
                        label={requiredLabel("Terminal RSPC Approved")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isTerminalRSPCApproved}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isTerminalRSPCApproved)}
                            checked={
                                props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.isTerminalRSPCApproved]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Terminal RSPC Approved - Actual Completion Date:")}
                        errorText={props.errorTexts.terminalRSPCApprovedDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.terminalRSPCApprovedDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.terminalRSPCApprovedDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.terminalRSPCApprovedDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        stretch
                        errorText={props.errorTexts.isTerminalHardwarePOCreated}
                        label={requiredLabel("Terminal Hardware PO Created")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isTerminalHardwarePOCreated}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isTerminalHardwarePOCreated)}
                            checked={
                                props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.isTerminalHardwarePOCreated]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Terminal Hardware PO Created - Actual Completion Date:")}
                        errorText={props.errorTexts.terminalHardwarePOCreatedDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.terminalHardwarePOCreatedDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.terminalHardwarePOCreatedDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.terminalHardwarePOCreatedDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        stretch
                        errorText={props.errorTexts.isTerminalHardwareDelivered}
                        label={requiredLabel("Terminal Hardware Delivered")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isTerminalHardwareDelivered}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isTerminalHardwareDelivered)}
                            checked={
                                props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.isTerminalHardwareDelivered]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Terminal Hardware Delivered - Actual Completion Date:")}
                        errorText={props.errorTexts.terminalHardwareDeliveredDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.terminalHardwareDeliveredDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.terminalHardwareDeliveredDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.terminalHardwareDeliveredDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        stretch
                        errorText={props.errorTexts.isTerminalHardwareInstalled}
                        label={requiredLabel("Terminal Hardware Installed")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isTerminalHardwareInstalled}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isTerminalHardwareInstalled)}
                            checked={
                                props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.isTerminalHardwareInstalled]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Terminal Hardware Installed - Actual Completion Date:")}
                        errorText={props.errorTexts.terminalHardwareInstalledDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.terminalHardwareInstalledDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.terminalHardwareInstalledDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.terminalHardwareInstalledDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);