import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export default class OrderValidation {
    static CIRCUIT_QUANTITY_LAG_ERROR_MESSAGE = "The sum of new and existing LAGs must equal the circuit quantity.";
    static ORDER_QUANTITY_MAX_LIMIT_ERROR_MESSAGE =
        `Quantity must be between 0 and ${Constants.CIRCUIT_MAX_QUANTITY} (inclusive)`;
    static CIRCUIT_QUANTITY_MAX_LIMIT_ERROR_MESSAGE =
        `Quantity must be between 1 and ${Constants.CIRCUIT_MAX_QUANTITY} (inclusive)`;

    static LAG_FIELD_IDS = [
        "netNewLagQuantity",
        "netNewLagCircuitCount",
        "existingLagQuantity",
        "existingLagCircuitCount",
        "circuitQuantity",
        "lacpProvider"
    ];

    static DEFAULT_ORDER_ERROR_TEXTS = {
        serviceType: Constants.ERROR_STRINGS.blankInput,
        businessNeed: Constants.ERROR_STRINGS.blankInput,
        orderType: Constants.ERROR_STRINGS.blankInput,
        asnId: Constants.ERROR_STRINGS.blankInput,
        providerName: Constants.ERROR_STRINGS.blankInput,
        requiredCompletionDate: Constants.ERROR_STRINGS.blankInput,
        transitType: Constants.ERROR_STRINGS.blankInput,
        peeringDBInternetExchangeName: Constants.ERROR_STRINGS.blankInput,
        siteAId: Constants.ERROR_STRINGS.blankInput,
        siteZId: Constants.ERROR_STRINGS.blankInput,
        contactId: Constants.ERROR_STRINGS.blankInput,
        provisionerId: Constants.ERROR_STRINGS.blankInput,
        engineerId: Constants.ERROR_STRINGS.blankInput
    };

    static EMPTY_ORDER_ERROR_TEXTS = {
        serviceType: "",
        businessNeed: "",
        orderType: "",
        asnId: "",
        providerName: "",
        requiredCompletionDate: "",
        contactId: "",
        provisionerId: "",
        engineerId: ""
    };

    static DEFAULT_ATTACHMENT_ERROR_TEXTS = {
        attachmentType: Constants.ERROR_STRINGS.blankInput,
        entityIdList: Constants.ERROR_STRINGS.blankMultiSelectErrorText,
        file: Constants.ERROR_STRINGS.missingFile
    };

    static BULK_UPDATE_ATTACHMENT_ERROR_TEXTS = {
        file: Constants.ERROR_STRINGS.missingFile
    };

    static DEFAULT_ORDER_ATTACHMENT_ERROR_TEXTS = {
        attachmentType: Constants.ERROR_STRINGS.blankInput,
        entityIdList: "",
        file: Constants.ERROR_STRINGS.missingFile
    };

    static EMPTY_ATTACHMENT_ERROR_TEXTS = {
        attachmentType: "",
        entityIdList: "",
        file: ""
    };

    static DEFAULT_BLOCKER_ERROR_TEXTS = {
        jepCode: Constants.ERROR_STRINGS.blankInput,
        responsibleContactId: Constants.ERROR_STRINGS.blankInput,
        stageName: Constants.ERROR_STRINGS.blankInput
    };

    static DEFAULT_ADD_CIRCUITS_ERROR_TEXTS_LACP = {
        portSize: Constants.ERROR_STRINGS.blankInput,
        loaDisposition: Constants.ERROR_STRINGS.blankInput,
        circuitQuantity: Constants.ERROR_STRINGS.blankInput,
        ipDisposition: Constants.ERROR_STRINGS.blankInput,
        lacpProvider: "",
        netNewLagQuantity: Constants.ERROR_STRINGS.blankInput,
        netNewLagCircuitCount: Constants.ERROR_STRINGS.blankInput,
        existingLagQuantity: Constants.ERROR_STRINGS.blankInput,
        existingLagCircuitCount: Constants.ERROR_STRINGS.blankInput
    };

    static DEFAULT_ADD_CIRCUITS_ERROR_TEXTS_NO_LACP = {
        portSize: Constants.ERROR_STRINGS.blankInput,
        loaDisposition: Constants.ERROR_STRINGS.blankInput,
        circuitQuantity: Constants.ERROR_STRINGS.blankInput,
        ipDisposition: Constants.ERROR_STRINGS.blankInput,
        lacpProvider: "",
        netNewLagQuantity: "",
        netNewLagCircuitCount: "",
        existingLagQuantity: "",
        existingLagCircuitCount: ""
    };

    static BACKBONE_ADD_CIRCUITS_ERROR_TEXTS = {
        portSize: Constants.ERROR_STRINGS.blankInput,
        circuitQuantity: Constants.ERROR_STRINGS.blankInput,
        linkHandoff: Constants.ERROR_STRINGS.blankInput,
        transportCorridor: Constants.ERROR_STRINGS.blankInput,
        maxLatencyProtectState: Constants.ERROR_STRINGS.blankInput,
        maxLatencySteadyState: Constants.ERROR_STRINGS.blankInput
    };

    static BACKBONE_PATH_ADD_CIRCUITS_ERROR_TEXTS = {
        portSize: Constants.ERROR_STRINGS.blankInput,
        circuitQuantity: Constants.ERROR_STRINGS.blankInput,
        linkHandoff: Constants.ERROR_STRINGS.blankInput,
        transportCorridor: Constants.ERROR_STRINGS.blankInput
    };

    static BACKBONE_SPAN_ADD_CIRCUITS_ERROR_TEXTS = {
        portSize: Constants.ERROR_STRINGS.blankInput,
        circuitQuantity: Constants.ERROR_STRINGS.blankInput,
        linkHandoff: Constants.ERROR_STRINGS.blankInput,
        transportCorridor: Constants.ERROR_STRINGS.blankInput,
        isProtected: Constants.ERROR_STRINGS.blankInput,
        maxLatencyProtectState: Constants.ERROR_STRINGS.blankInput,
        maxLatencySteadyState: Constants.ERROR_STRINGS.blankInput,
        spanType: Constants.ERROR_STRINGS.blankInput
    };

    static EMPTY_ADD_CIRCUITS_ERROR_TEXTS = {
        portSize: "",
        loaDisposition: "",
        linkHandoff: "",
        circuitQuantity: "",
        ipDisposition: "",
        lacpProvider: "",
        netNewLagQuantity: "",
        netNewLagCircuitCount: "",
        existingLagQuantity: "",
        existingLagCircuitCount: "",
        workOrderId: "",
        expectedDeliveryDate: "",
        term: "",
        currency: "",
        spanNumber: "",
        spanServiceType: "",
        nrc: "",
        mrc: ""
    };

    static BUSINESS_DEVELOPER_NEW_ORDER_INFO_ERROR_TEXTS = {
        serviceType: "",
        orderType: "",
        businessNeed: "",
        requiredCompletionDate: ""
    };

    static BUSINESS_DEVELOPER_DESIGN_STAGE_ERROR_TEXTS = {
        circuitQuantity: "",
        portSize: "",
        ipDisposition: "",
        loaDisposition: "",
        netNewLagQuantity: "",
        netNewLagCircuitCount: "",
        existingLagQuantity: "",
        existingLagCircuitCount: ""
    };

    static BUSINESS_DEVELOPER_SUBMIT_STAGE_ERROR_TEXTS = {
        provisionerId: "",
        engineerId: ""
    };

    static BIZ_DEV_SUBMIT_STAGE_ERROR_BACKBONE_BFB_FINALCAT = {
        pathName: Constants.ERROR_STRINGS.blankInput
    };

    static BIZ_DEV_SUBMIT_STAGE_BLANK = {
        pathName: ""
    };

    static IP_ALLOCATION_STAGE_ERROR_TEXTS = {
        [Constants.ATTRIBUTES.amazonIPv4]: "",
        [Constants.ATTRIBUTES.amazonIPv6]: "",
        [Constants.ATTRIBUTES.providerIPv4]: "",
        [Constants.ATTRIBUTES.providerIPv6]: "",
        [Constants.ATTRIBUTES.bgpIPv4MD5Key]: "",
        [Constants.ATTRIBUTES.bgpIPv6MD5Key]: ""
    };

    static IP_TESTING_STAGE_ERROR_TEXTS = {
        [Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4]: "",
        [Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6]: "",
        [Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4]: "",
        [Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6]: ""
    };

    static OPTICAL_BACKBONE_ERROR_TEXTS = {
        [Constants.ATTRIBUTES.fiberHandoverMcm]: "",
        [Constants.ATTRIBUTES.mgmtCutsheetMcm]: "",
        [Constants.ATTRIBUTES.opsChecklistMcm]: "",
        [Constants.ATTRIBUTES.opticalDesignString]: Constants.ERROR_STRINGS.invalidOpticalDesignString
    }

    static LATENCY_FIELDS = new Set([
        Constants.ATTRIBUTES.maxLatencySteadyState,
        Constants.ATTRIBUTES.maxLatencyProtectState,
        Constants.ATTRIBUTES.rfcTestingLatency
    ]);

    static getNewAttachmentErrorTexts = (entityType) => {
        if (entityType === Constants.ORDER_ENTITY_TYPE) {
            return OrderValidation.DEFAULT_ORDER_ATTACHMENT_ERROR_TEXTS;
        }
        if (entityType === Constants.CIRCUIT_DESIGN_ENTITY_TYPE) {
            return OrderValidation.DEFAULT_ATTACHMENT_ERROR_TEXTS;
        }
        if (entityType === Constants.BULK_UPDATE_JOB_ENTITY_TYPE) {
            return OrderValidation.BULK_UPDATE_ATTACHMENT_ERROR_TEXTS;
        }
        return {}; // For lint's `consistent-return` rule
    };

    static ORDER_PAGE_MODAL_TYPES = {
        attachment: "attachment",
        templateGenerator: "templateGenerator",
        blocker: "blocker",
        tag: "tag",
        completion: "completion",
        cancel: "cancel",
        siteChange: "siteChange",
        providerChange: "providerChange"
    };

    /**
     * This is specifically for the edit buttons on most of the stages, not whether the order should be disabled in
     * general (though they are not mutually exclusive). We are also talking about stages after order acceptance.
     */
    static isOrderStagesEditButtonDisabled = order =>
        HelperFunctions.isStageCompleted(order.stageStatusMap[Constants.STAGE_NAMES.completeOrder])
        || HelperFunctions.isOrderCancelled(order.stageStatusMap)
        || (order.orderType === Constants.ORDER_TYPES.DECOMMISSION && !order[Constants.ATTRIBUTES.hasBeenApproved]
            // optical span decommission order does not have approval stage
            && !HelperFunctions.isOrderOpticalSpanOrder(order));

    static disableAttachmentButton = (circuitLength, orderCompleted, order, editButtonsDisabled) =>
        circuitLength === 0
        || orderCompleted
        || OrderValidation.isOrderStagesEditButtonDisabled(order)
        || editButtonsDisabled;

    static disableEditButton = (circuitLength, isDataLoaded, order, editButtonsDisabled) =>
        circuitLength === 0
        || !isDataLoaded
        || OrderValidation.isOrderStagesEditButtonDisabled(order)
        || editButtonsDisabled;

    static getIdsAndIndex = (input) => {
        // To determine the descriptors for topologyMap we use a second separator: UUID_SEPARATOR
        const descriptors = input.evt.target.id.split(Constants.SEPARATOR).find(el =>
            el.includes(Constants.UUID_SEPARATOR));
        // To find the attributeId, we perform the same operation except that we find the element that does not contain
        // a hyphen, which will always be the attributeId
        const attributeId = input.evt.target.id.split(Constants.SEPARATOR).find(el =>
            !el.includes(Constants.UUID_SEPARATOR));

        return {
            attributeId,
            descriptors
        };
    };

    static validateInput = (input) => {
        const output = input;
        const { attributeId, descriptors } = OrderValidation.getIdsAndIndex(input);

        output.attributeId = attributeId;

        const updatableAttributes = [Constants.ATTRIBUTES.providerName, Constants.ATTRIBUTES.asnId,
            Constants.ATTRIBUTES.siteAId, Constants.ATTRIBUTES.siteZId, Constants.ATTRIBUTES.ownerId,
            Constants.ATTRIBUTES.customerFabric, Constants.ATTRIBUTES.businessNeed, Constants.ATTRIBUTES.provisionerId,
            Constants.ATTRIBUTES.engineerId, Constants.ATTRIBUTES.transitType, Constants.ATTRIBUTES.ipDisposition,
            Constants.ATTRIBUTES.loaDisposition, Constants.ATTRIBUTES.portSize, Constants.ATTRIBUTES.contactId,
            Constants.ATTRIBUTES.transportCorridor, Constants.ATTRIBUTES.orderType,
            Constants.ATTRIBUTES.linkHandoff, Constants.ATTRIBUTES.businessOperationsId,
            Constants.ATTRIBUTES.peeringDBInternetExchangeName, Constants.ATTRIBUTES.fiberType,
            Constants.ATTRIBUTES.connectorType, Constants.ATTRIBUTES.isProtected,
            Constants.ATTRIBUTES.spanType, Constants.ATTRIBUTES.projectType];

        const updatableInputAttributes = [Constants.ATTRIBUTES.spanName, Constants.ATTRIBUTES.opticalDesignString,
            Constants.ATTRIBUTES.opticalEngineerId, Constants.ATTRIBUTES.fbn];
        if (!HelperFunctions.isProd()) {
            updatableAttributes.push(Constants.ATTRIBUTES.pathName);
        }

        let inputValue;
        // Handle obtaining the inputted value and assigned it to the order object
        if (attributeId === Constants.ATTRIBUTES.serviceType) {
            inputValue = output.evt.detail.selectedOption.label;
        } else if (updatableAttributes.includes(attributeId)) {
            inputValue = output.evt.detail.selectedOption.value;
        } else if (updatableInputAttributes.includes(attributeId)) {
            inputValue = output.evt.detail.value;
        } else if (attributeId === Constants.ATTRIBUTES.installAndDecommission) {
            inputValue = output.evt.detail.selectedOption ? output.evt.detail.selectedOption.value : null;
        } else if (attributeId === Constants.ATTRIBUTES.lacpProvider) {
            inputValue = output.evt.detail.selectedOption ? output.evt.detail.selectedOption.value.toString() : null;
        } else if ([Constants.ATTRIBUTES.orderSentToSupplier, Constants.ATTRIBUTES.carrierBuildCompleted,
            Constants.ATTRIBUTES.congoRequired].includes(attributeId)) {
            inputValue = output.evt.detail.checked;
        } else if ([Constants.ATTRIBUTES.topologyMap].includes(attributeId) &&
            descriptors.split(Constants.UUID_SEPARATOR)[2] === Constants.TOPOLOGY_CONSTANTS.remove) {
            inputValue = output.evt.target.id;
        } else {
            inputValue = output.evt.detail.value || "";
        }

        // In case of transportCorridor, then we have also added a field called group which is transportGroup
        // If the input value is empty, pass null as the value into the order object so a backend error doesn't
        // trigger (dynamo doesn't like blank strings)
        // The requiredCompletionDate is a special field, it cannot be set to null due to DatePicker module
        // We shouldn't be setting the lacpProvider to null either since its a boolean value
        if ([Constants.ATTRIBUTES.transportCorridor].includes(attributeId)) {
            output.transport[Constants.ATTRIBUTES.transportCorridor] = inputValue;
            output.transport[Constants.ATTRIBUTES.transportRegion] = output.evt.detail.selectedOption.group;
        } else if (attributeId === Constants.ATTRIBUTES.topologyMap) {
            // we need to make an update to the topologyMap
            const existingPosition = descriptors.split(Constants.UUID_SEPARATOR)[0];
            const arrayIndex = descriptors.split(Constants.UUID_SEPARATOR)[1];
            const topologyProperty = descriptors.split(Constants.UUID_SEPARATOR)[2];

            if (topologyProperty === Constants.TOPOLOGY_CONSTANTS.position) {
                // This branch handles the case of user modifying the position column in the topology UI
                // Essentially they are taking that "visual" row and moving it around the nested map
                // First we get the existing topologyObjectToMove from the old existingPosition
                // Then we add it to the array for the new position (in case it doesn't exist, we must make a new one
                const topologyMap = output.order[Constants.ATTRIBUTES.topologyMap];
                const topologyObjectToMove = HelperFunctions.deepClone(topologyMap[existingPosition][arrayIndex]);

                // Also dont bother moving it if the input is "null" value like blank string or the same as
                // current value
                if (existingPosition !== inputValue && !!inputValue) {
                    const newArray = topologyMap[inputValue] === undefined ?
                        [].concat(topologyObjectToMove) : topologyMap[inputValue].concat(topologyObjectToMove);
                    topologyMap[inputValue] = newArray;

                    // Finally delete the item from the old location
                    // If it's the last object at that location, then delete the entire "position" from the
                    // topologyMap itself.
                    topologyMap[existingPosition].splice(arrayIndex, 1);
                    if (topologyMap[existingPosition].length === 0) {
                        delete topologyMap[existingPosition];
                    }
                }
            } else if (topologyProperty === Constants.TOPOLOGY_CONSTANTS.remove) {
                const topologyMap = output.order[Constants.ATTRIBUTES.topologyMap];
                // delete the item from the original position
                topologyMap[existingPosition].splice(arrayIndex, 1);
                if (topologyMap[existingPosition].length === 0) {
                    delete topologyMap[existingPosition];
                }
            } else if (Constants.TOPOLOGY_CONSTANTS.sourceSystem === topologyProperty) {
                inputValue = output.evt.detail.selectedOption.value;
                if (inputValue === Constants.TOPOLOGY_CONSTANTS.linkService) {
                    output.order.topologyMap[existingPosition][arrayIndex].orderId = "";
                }

                output.order.topologyMap[existingPosition][arrayIndex].topologyCustomerFabric = "";
                output.order[Constants.ATTRIBUTES.topologyMap][existingPosition][arrayIndex].sourceSystem = inputValue;
            } else if ([Constants.ATTRIBUTES.siteAId, Constants.ATTRIBUTES.siteZId,
                Constants.TOPOLOGY_CONSTANTS.topologyCustomerFabric].includes(topologyProperty)) {
                inputValue = !output.evt.detail.selectedOption ? "" : output.evt.detail.selectedOption.value;
                output.order[Constants.ATTRIBUTES.topologyMap][existingPosition][arrayIndex][topologyProperty] =
                    inputValue;
            } else {
                output.order[Constants.ATTRIBUTES.topologyMap][existingPosition][arrayIndex][topologyProperty] =
                    inputValue;
            }
        } else if (OrderValidation.LATENCY_FIELDS.has(attributeId)) {
            output.order[attributeId] = inputValue;
            if (inputValue === "") {
                output.orderErrorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
            } else if (parseInt(output.order[Constants.ATTRIBUTES.maxLatencySteadyState], 10)
                > parseInt(output.order[Constants.ATTRIBUTES.maxLatencyProtectState], 10)) {
                // If Steady State is more than protect state show error
                output.orderErrorTexts[Constants.ATTRIBUTES.maxLatencySteadyState] =
                    Constants.ERROR_STRINGS.SteadyStateMoreThanProtectState;
                // When editing max Latency Protect State and there is an existing error for range
                // and new error for steady state less than protect state
                // remove range error if range in valid
                if (HelperFunctions.isStringInsideRange(output.order.maxLatencyProtectState, 0, 300)) {
                    output.orderErrorTexts[Constants.ATTRIBUTES.maxLatencyProtectState] = "";
                }
            } else if (!HelperFunctions.isStringInsideRange(output.order[attributeId], 0, 300)) {
                output.orderErrorTexts[attributeId] = Constants.ERROR_STRINGS.invalidLatencyRange;
            } else {
                output.orderErrorTexts[attributeId] = "";
                // If editing min protect state and there are no errors
                // remove steady state less than protect state error
                if (output.orderErrorTexts[Constants.ATTRIBUTES.maxLatencySteadyState] ===
                    Constants.ERROR_STRINGS.SteadyStateMoreThanProtectState) {
                    output.orderErrorTexts[Constants.ATTRIBUTES.maxLatencySteadyState] = "";
                }
            }
        } else if (!HelperFunctions.isProd() && attributeId === Constants.ATTRIBUTES.pathName) {
            output.order.canonicalPathId = inputValue;
            output.order.pathName = output.evt.detail.selectedOption.label;
        } else if (![Constants.ATTRIBUTES.requiredCompletionDate, Constants.ATTRIBUTES.lacpProvider,
            Constants.ATTRIBUTES.estimatedCarrierBuildCompletionDate,
            Constants.ATTRIBUTES.orderSentToSupplier,
            Constants.ATTRIBUTES.carrierBuildCompleted]
            .includes(attributeId)) {
            output.order[attributeId] = inputValue || null;
        } else {
            output.order[attributeId] = inputValue;
        }
        if (attributeId === Constants.ATTRIBUTES.orderType || attributeId === Constants.ATTRIBUTES.serviceType) {
            // we need to reset business need if the order type has been changed
            output.order.businessNeed = "";
            output.orderErrorTexts[Constants.ATTRIBUTES.businessNeed] = Constants.ERROR_STRINGS.blankInput;
            output.orderErrorTexts[Constants.ATTRIBUTES.serviceType] = "";
        }

        // Whenever the provider name is changed, we clear out the ASN field because ASNs are directly related
        // to a specific provider and cannot exist without that provider
        if (attributeId === Constants.ATTRIBUTES.providerName) {
            output.order.asnId = "";
            output.order.serviceType = "";
            output.order.orderType = "";
            output.order.siteAId = "";
        }
        // Whenever the LACP Provider is changed to no, we clear our the four LAG fields and their error messages,
        // as the fields are disabled when LACP provider is no
        if (attributeId === Constants.ATTRIBUTES.lacpProvider && !HelperFunctions.parseBoolean(inputValue)) {
            output.order.netNewLagQuantity = "";
            output.order.netNewLagCircuitCount = "";
            output.order.existingLagQuantity = "";
            output.order.existingLagCircuitCount = "";
            output.orderErrorTexts.netNewLagQuantity = "";
            output.orderErrorTexts.netNewLagCircuitCount = "";
            output.orderErrorTexts.existingLagQuantity = "";
            output.orderErrorTexts.existingLagCircuitCount = "";
        }
        // Whenever the LACP Provider is changed to yes, we assign the value to 0. The validation to check whether
        // or not the values add up to the circuit quantity is checked below, so we don't need to take care of that here
        if (attributeId === Constants.ATTRIBUTES.lacpProvider && !!HelperFunctions.parseBoolean(inputValue)) {
            output.order.netNewLagQuantity = "0";
            output.order.netNewLagCircuitCount = "0";
            output.order.existingLagQuantity = "0";
            output.order.existingLagCircuitCount = "0";
        }

        if (output.order[Constants.ATTRIBUTES.orderType] === Constants.ORDER_TYPES.DECOMMISSION) {
            output.orderErrorTexts[Constants.ATTRIBUTES.provisionerId] = "";
        }
        // Handle error messages
        if ([Constants.ATTRIBUTES.serviceType, Constants.ATTRIBUTES.businessNeed, Constants.ATTRIBUTES.orderType,
            Constants.ATTRIBUTES.circuitQuantity, Constants.ATTRIBUTES.portSize, Constants.ATTRIBUTES.ipDisposition,
            Constants.ATTRIBUTES.loaDisposition, Constants.ATTRIBUTES.contactId, Constants.ATTRIBUTES.linkHandoff,
            Constants.ATTRIBUTES.providerName, Constants.ATTRIBUTES.asnId, Constants.ATTRIBUTES.siteAId,
            Constants.ATTRIBUTES.siteZId, Constants.ATTRIBUTES.peeringDBInternetExchangeName,
            Constants.ATTRIBUTES.transitType, Constants.ATTRIBUTES.provisionerId, Constants.ATTRIBUTES.engineerId,
            Constants.ATTRIBUTES.transportCorridor, Constants.ATTRIBUTES.transportRegion,
            Constants.ATTRIBUTES.ownerId, Constants.ATTRIBUTES.customerFabric, Constants.ATTRIBUTES.isProtected,
            Constants.ATTRIBUTES.spanType]
            .includes(attributeId)
            || ((HelperFunctions.isOrderPathOrder(output.order))
                && attributeId === Constants.ATTRIBUTES.pathName)) {
            if (!inputValue) {
                output.orderErrorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
            } else {
                output.orderErrorTexts[attributeId] = "";
            }
        } else if ([Constants.ATTRIBUTES.requiredCompletionDate,
            Constants.ATTRIBUTES.estimatedCarrierBuildCompletionDate].includes(attributeId)) {
            const parsedDate = Date.parse(output.evt.detail.value.trim());
            if (!inputValue) {
                output.orderErrorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
            } else if (!parsedDate) {
                output.orderErrorTexts[attributeId] = "Invalid date format";
            } else if ([Constants.ATTRIBUTES.estimatedCarrierBuildCompletionDate].includes(attributeId) &&
                Date.now() > parsedDate) {
                output.orderErrorTexts[attributeId] = "Date must be in the future";
            } else {
                output.orderErrorTexts[attributeId] = "";
            }
        }
        // Handle error message for order ttUrl
        if ([Constants.ATTRIBUTES.ttUrl, Constants.ATTRIBUTES.lhDesignSIM].includes(attributeId)) {
            output.orderErrorTexts[attributeId] = HelperFunctions.validateTTURL(inputValue);
        }
        // Handle error message for relatedOrderId
        if (output.order[Constants.ATTRIBUTES.installAndDecommission]
            && output.order.orderType === Constants.ORDER_TYPES.DECOMMISSION
            && !output.order[Constants.ATTRIBUTES.relatedOrderId]) {
            output.orderErrorTexts[Constants.ATTRIBUTES.relatedOrderId] = Constants.ERROR_STRINGS.blankInput;
        } else {
            output.orderErrorTexts[Constants.ATTRIBUTES.relatedOrderId] = "";
        }
        if (!output.order[Constants.ATTRIBUTES.installAndDecommission]
            || output.order.orderType !== Constants.ORDER_TYPES.DECOMMISSION) {
            output.order[Constants.ATTRIBUTES.relatedOrderId] = "";
        }
        // Handle error messages for special case where if the order type is install then service type cannot be set
        // to paid peering https://issues.amazon.com/issues/FremontNEST-2308. This only applies for newly created orders
        // and not for existing paid peering orders that are in progress
        if (!output.order[Constants.ATTRIBUTES.orderId]
            && output.order[Constants.ATTRIBUTES.orderType] === Constants.ORDER_TYPES.INSTALL
            && output.order[Constants.ATTRIBUTES.serviceType] === Constants.SERVICE_TYPES.PAID_PEERING) {
            output.orderErrorTexts[Constants.ATTRIBUTES.serviceType] = "Paid Peering service is not supported " +
                "for install order type.";
        }
        // Handle error messages for circuit quantity and LAG fields.
        // Before we check to make sure that the quantities add up to the circuit quantity, we need to make sure the
        // input for each field is valid. This is why we use the `||` operator: if an error is there, we don't override
        // it with subsequent checks until that one is fixed (i.e we can't check to see if they add up to the circuit
        // quantity if the value is a negative value, which is invalid)
        if (output.order.serviceType !== Constants.SERVICE_TYPES.BACKBONE
            && !!HelperFunctions.parseBoolean(output.order.lacpProvider)
            && OrderValidation.LAG_FIELD_IDS.includes(attributeId)) {
            // Start with blank errors
            output.orderErrorTexts.netNewLagQuantity = "";
            output.orderErrorTexts.netNewLagCircuitCount = "";
            output.orderErrorTexts.existingLagQuantity = "";
            output.orderErrorTexts.existingLagCircuitCount = "";

            // Check is whether a quantity entered is between 0-100.
            if (!HelperFunctions.isStringInsideRange(output.order.netNewLagQuantity, 0,
                Constants.CIRCUIT_MAX_QUANTITY)) {
                output.orderErrorTexts.netNewLagQuantity = OrderValidation.ORDER_QUANTITY_MAX_LIMIT_ERROR_MESSAGE;
            }
            if (!HelperFunctions.isStringInsideRange(output.order.netNewLagCircuitCount, 0,
                Constants.CIRCUIT_MAX_QUANTITY)) {
                output.orderErrorTexts.netNewLagCircuitCount = OrderValidation.ORDER_QUANTITY_MAX_LIMIT_ERROR_MESSAGE;
            }
            if (!HelperFunctions.isStringInsideRange(output.order.existingLagQuantity, 0,
                Constants.CIRCUIT_MAX_QUANTITY)) {
                output.orderErrorTexts.existingLagQuantity = OrderValidation.ORDER_QUANTITY_MAX_LIMIT_ERROR_MESSAGE;
            }
            if (!HelperFunctions.isStringInsideRange(output.order.existingLagCircuitCount, 0,
                Constants.CIRCUIT_MAX_QUANTITY)) {
                output.orderErrorTexts.existingLagCircuitCount =
                    OrderValidation.ORDER_QUANTITY_MAX_LIMIT_ERROR_MESSAGE;
            }
            if (!HelperFunctions.isStringInsideRange(output.order.circuitQuantity, 1, Constants.CIRCUIT_MAX_QUANTITY)) {
                output.orderErrorTexts.circuitQuantity = OrderValidation.CIRCUIT_QUANTITY_MAX_LIMIT_ERROR_MESSAGE;
            }

            // If lag quantity is 0, circuits per lag must be 0
            if (output.order[Constants.ATTRIBUTES.netNewLagQuantity] === "0"
                && output.order[Constants.ATTRIBUTES.netNewLagCircuitCount] !== "0") {
                output.orderErrorTexts.netNewLagCircuitCount =
                    (output.orderErrorTexts.netNewLagCircuitCount || Constants.ERROR_STRINGS.mustBeZero);
            }
            if (output.order[Constants.ATTRIBUTES.existingLagQuantity] === "0"
                && output.order[Constants.ATTRIBUTES.existingLagCircuitCount] !== "0") {
                output.orderErrorTexts.existingLagCircuitCount =
                    (output.orderErrorTexts.existingLagCircuitCount || Constants.ERROR_STRINGS.mustBeZero);
            }

            // If lag quantity is not 0, circuits per lag cannot be 0
            if (output.order[Constants.ATTRIBUTES.netNewLagQuantity] !== "0"
                && output.order[Constants.ATTRIBUTES.netNewLagCircuitCount] === "0") {
                output.orderErrorTexts.netNewLagCircuitCount =
                    (output.orderErrorTexts.netNewLagCircuitCount || Constants.ERROR_STRINGS.cannotBeZero);
            }
            if (output.order[Constants.ATTRIBUTES.existingLagQuantity] !== "0"
                && output.order[Constants.ATTRIBUTES.existingLagCircuitCount] === "0") {
                output.orderErrorTexts.existingLagCircuitCount =
                    (output.orderErrorTexts.existingLagCircuitCount || Constants.ERROR_STRINGS.cannotBeZero);
            }

            // We need to check this even if this field is not modified (i.e when the circuit quantity is changed)
            // If there is another error on one of those fields, which is likely a validation error, we need the user
            // to correct those before we show this error since they likely have an invalid input anyways
            if (HelperFunctions.parseBoolean(output.order.lacpProvider) && output.order.circuitQuantity) {
                const computedCircuitQuantity = ((output.order.netNewLagQuantity * output.order.netNewLagCircuitCount) +
                    (output.order.existingLagQuantity * output.order.existingLagCircuitCount));
                if (HelperFunctions.parseInt(output.order.circuitQuantity) !== computedCircuitQuantity) {
                    output.orderErrorTexts.netNewLagQuantity = (output.orderErrorTexts.netNewLagQuantity
                        || OrderValidation.CIRCUIT_QUANTITY_LAG_ERROR_MESSAGE);
                    output.orderErrorTexts.netNewLagCircuitCount = (output.orderErrorTexts.netNewLagCircuitCount
                        || OrderValidation.CIRCUIT_QUANTITY_LAG_ERROR_MESSAGE);
                    output.orderErrorTexts.existingLagQuantity = (output.orderErrorTexts.existingLagQuantity
                        || OrderValidation.CIRCUIT_QUANTITY_LAG_ERROR_MESSAGE);
                    output.orderErrorTexts.existingLagCircuitCount = (output.orderErrorTexts.existingLagCircuitCount
                        || OrderValidation.CIRCUIT_QUANTITY_LAG_ERROR_MESSAGE);
                }
            }
        }

        if (attributeId === Constants.ATTRIBUTES.opticalDesignString
            && !HelperFunctions.isValidOpticalDesignString(output.order.opticalDesignString)) {
            output.orderErrorTexts.opticalDesignString = (output.orderErrorTexts.opticalDesignString
                || OrderValidation.OPTICAL_BACKBONE_ERROR_TEXTS[attributeId]);
        }

        // store optical meta from input fields
        if (Constants.OPTICAL_BACKBONE_ORDER_META_STRING_ATTRIBUTES.includes(attributeId)) {
            output.order.opticalMetaMap[attributeId] = output.evt.detail.value;
        }
        // store optical meta from checkbox fields
        if (Constants.OPTICAL_BACKBONE_ORDER_META_BOOLEAN_ATTRIBUTES.includes(attributeId)) {
            output.order.opticalMetaMap[attributeId] = output.evt.detail.checked ?
                Constants.TRUE_STRING : Constants.FALSE_STRING;
        }
        return output;
    };

    /**
     * This function obtains all of the associated objects from all of the position maps for each circuitDesign so that
     * each object can be obtained and the appropriate value displayed to the user
     * @param input
     * @param flashBarErrorFunction
     */
    static getAllAssociatedComponentObjects = async (input, flashBarErrorFunction) => {
        const output = input;
        const componentIdToObjectMap = {};

        let componentResponse = {};
        try {
            // Here we load each circuitDesignObject and add it to the componentIdToObjectMap
            componentResponse = await output.fremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CIRCUIT_DESIGN,
                output.circuitDesignIds,
                input.auth,
                true
            );
        } catch (error) {
            flashBarErrorFunction(false, error, false);
        }

        Object.keys(componentResponse).forEach(key =>
            componentResponse[key].forEach((component) => {
                if (component) {
                    Object.assign(componentIdToObjectMap, { [component[`${key.slice(0, -1)}Id`]]: component });
                }
            }));

        return componentIdToObjectMap;
    };

    /**
     * This method is used for fetching given note information based on list of noteIds
     */
    static fetchLatestRejectionNote = async (fremontBackendClient, noteIdList, auth, reason) => {
        if (noteIdList.length === 0) {
            return undefined;
        }
        const response = await fremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.NOTE, noteIdList, auth
        );
        // He we sort the notes in descending order based on createdTime
        HelperFunctions.sortObjectsByField(response.notes, "createdTime", true);
        return response.notes.find(note => note.noteBody.includes(reason));
    };

    static BUSINESS_NEED_OPTIONS_DECOM_BACKBONE = [
        "Decommissioning Site/Decrease Traffic",
        "Cost Savings",
        "Express Route",
        "Latency Requirements",
        "Line-side Bandwidth Upgrade",
        "Network Topology Change",
        "Route Availability/Uptime",
        "Route Change For Diversity",
        "Vendor Reliability/Issue"
    ];

    static BUSINESS_NEED_CHANGE_ORDER = "Change Order";

    static BUSINESS_NEED_OPTIONS_INSTALL = [
        "Scaling",
        "New PoP"
    ];

    static BUSINESS_NEED_OPTIONS_COMMON_INTERCONNECT_EXCEPT_DECOM = [
        "CDN Scaling",
        "Customer Scaling",
        "Diversification",
        "iFOOB Scaling",
        "IX to PNI conversion",
        "New interconnect metro with peer",
        "New Peer",
        "Scaling (routine capacity upgrade from high utilization)"
    ];

    static BUSINESS_NEED_OPTIONS_COMMON_INTERCONNECT_DECOM = [
        "CDN Scaling",
        "Customer Scaling",
        "Diversification",
        "Decommission",
        "iFOOB Scaling",
        "IX to PNI conversion",
        "Scaling (routine capacity upgrade from high utilization)"
    ];

    static getBusinessNeedOptions = (orderType, serviceType) => {
        let options = [];
        if (Constants.ORDER_TYPES.DECOMMISSION === orderType
            && Constants.SERVICE_TYPES.BACKBONE === serviceType) {
            options = OrderValidation.BUSINESS_NEED_OPTIONS_DECOM_BACKBONE;
        } else if (Constants.ORDER_TYPES.INSTALL === orderType) {
            options = OrderValidation.BUSINESS_NEED_OPTIONS_INSTALL;
        } else if (Constants.BILLING_ORDER_TYPES.concat([Constants.ORDER_TYPES.CHANGE]).includes(orderType)) {
            // Change Order is always pre-populated and disabled
            options = [OrderValidation.BUSINESS_NEED_CHANGE_ORDER];
        }

        if (Constants.ORDER_TYPES.FABRIC_MIGRATION === orderType) {
            options = [OrderValidation.BUSINESS_NEED_CHANGE_ORDER];
        }

        if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)) {
            if (Constants.ORDER_TYPES.DECOMMISSION === orderType) {
                options = options.concat(OrderValidation.BUSINESS_NEED_OPTIONS_COMMON_INTERCONNECT_DECOM);
            } else {
                options = options.concat(OrderValidation.BUSINESS_NEED_OPTIONS_COMMON_INTERCONNECT_EXCEPT_DECOM);
            }
        }

        return HelperFunctions.createSelectedOptions(options);
    };
}