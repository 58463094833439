import React from "react";
import { Link } from "@amzn/awsui-components-react/polaris";
import {
    FremontInput,
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const generateCircuitActivationColumnDefinitions = (order) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.circuitActivation], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.circuitActivationStatus,
            header: "Activation Status",
            description: "Activation status of the circuit",
            width: Constants.COLUMN_WIDTHS.completeColumn,
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.circuitActivationStatus] :
                    <div className={HelperFunctions.assignShortTableClass(item.numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.ATTRIBUTES.circuitActivationStatus}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={Constants.COMPLETION_OPTIONS}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.circuitActivationStatus]
                            )}
                            onChange={item.handleStageInputChange}
                            disabled={item.isUpdateStageInProgress || item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>
            )
        },
        {
            id: Constants.ATTRIBUTES.circuitBandwidth,
            header: "Bandwidth",
            description: "Circuit bandwidth for this circuit",
            cell: item => item[Constants.ATTRIBUTES.circuitBandwidth] || item[Constants.ATTRIBUTES.portBandwidth]
        },
        {
            id: Constants.COMPONENT_NAMES.providerCircuitA,
            header: "Provider Circuit ID",
            description: "Provider circuit of the circuit",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => item[Constants.COMPONENT_NAMES.providerCircuitA]
        }
    ];

    if (!Object.keys(order.stageStatusMap).includes(Constants.STAGE_NAMES.demarcAndCfaStage)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.demarcAndCfaA,
                header: `CFA A`,
                description: `CFA A of the circuit`,
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (
                    !item.editable ?
                        item[Constants.COMPONENT_NAMES.demarcAndCfaA] :
                        <FremontInput
                            id={`${Constants.COMPONENT_NAMES.demarcAndCfaA}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            value={item[Constants.COMPONENT_NAMES.demarcAndCfaA]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                )
            },
            {
                id: Constants.COMPONENT_NAMES.demarcAndCfaZ,
                header: `CFA Z`,
                description: `CFA Z of the circuit`,
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (
                    !item.editable ?
                        item[Constants.COMPONENT_NAMES.demarcAndCfaZ] :
                        <FremontInput
                            id={`${Constants.COMPONENT_NAMES.demarcAndCfaZ}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            value={item[Constants.COMPONENT_NAMES.demarcAndCfaZ]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                )
            }
        );
    }

    return columnDefinitions;
};

export default generateCircuitActivationColumnDefinitions;
