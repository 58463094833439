import React from "react";
import {
    Box,
    FormField,
    Grid
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontInput,
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import FremontBackendClient from "common/FremontBackendClient";

/**
 * Search Component is a component used for searching various objects in Fremont.
 */
export class SearchHandler {
    static TYPES = {
        CreateCircuitDesignComponentModal: "CreateCircuitDesignComponentModal",
        BillingSegmentRecord: "BillingSegmentRecord",
        SelectCircuitsHandler: "SelectCircuitsHandler"
    };

    /**
     * Gets list of search parameters for decom order
     * @param type of the page this request is coming from
     * @param order order object
     * @param siteNames siteNames map
     * @returns {searchParameters} list of searchParameters based on serviceType
     */
    static generateSearchParameters = (type, order, siteNames) => {
        if (type === SearchHandler.TYPES.CreateCircuitDesignComponentModal) {
            return [
                {
                    id: "searchParam1",
                    parameterName: { label: "Service Type", id: Constants.ATTRIBUTES.serviceType },
                    parameterValue: "Backbone",
                    errorText: ""
                },
                {
                    id: "searchParam2",
                    parameterName: { label: "Consumable", id: Constants.ATTRIBUTES.consumableForType },
                    parameterValue: order[Constants.ATTRIBUTES.orderType],
                    errorText: ""
                },
                {
                    id: "searchParam3",
                    parameterName: { label: "Customer/Fabric", id: Constants.ATTRIBUTES.customerFabric },
                    parameterValue: "",
                    errorText: ""
                },
                {
                    id: "searchParam4",
                    parameterName: { label: "Provider Name", id: Constants.ATTRIBUTES.providerName },
                    parameterValue: "",
                    errorText: ""
                },
                {
                    id: "searchParam5",
                    parameterName: { label: "Order ID", id: Constants.ATTRIBUTES.orderId },
                    parameterValue: "",
                    errorText: ""
                }
            ];
        }
        if (type === SearchHandler.TYPES.BillingSegmentRecord) {
            return [
                {
                    id: "searchParam1",
                    parameterName: { label: "Service Type", id: Constants.ATTRIBUTES.serviceType },
                    parameterValue: Constants.SERVICE_TYPES.BACKBONE,
                    errorText: ""
                },
                {
                    id: "searchParam2",
                    parameterName: { label: "Provider Name", id: Constants.ATTRIBUTES.providerName },
                    parameterValue: "",
                    errorText: ""
                }
            ];
        }
        if (type === SearchHandler.TYPES.SelectCircuitsHandler) {
            const searchParameters = [
                {
                    id: "searchParam1",
                    parameterName: { label: "Service Type", id: Constants.ATTRIBUTES.serviceType },
                    parameterValue: order.serviceType,
                    errorText: ""
                },
                {
                    id: "searchParam2",
                    parameterName: { label: "Provider Name", id: Constants.ATTRIBUTES.providerName },
                    parameterValue: order.providerName,
                    errorText: ""
                },
                // Site A is required for all circuit types so that is what we search on by default
                {
                    id: "searchParam3",
                    parameterName: { label: "Site A Name", id: "siteAName" },
                    parameterValue: siteNames.siteAName,
                    errorText: ""
                }
            ];
            // Site Z is required only for backbone circuit types so that is what we search on by default
            if (order.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
                searchParameters.push(
                    {
                        id: "searchParam4",
                        parameterName: { label: "Site Z Name", id: "siteZName" },
                        parameterValue: siteNames.siteZName,
                        errorText: ""
                    },
                    {
                        id: "searchParam5",
                        parameterName: { label: "Customer/Fabric", id: "customerFabric" },
                        parameterValue: order.orderType === Constants.ORDER_TYPES.FABRIC_MIGRATION
                            ? order.originalCustomerFabric : order.customerFabric,
                        errorText: ""
                    }
                );
                if (HelperFunctions.isProviderAmazonInternal(order.providerName)) {
                    searchParameters.push(
                        {
                            id: "searchParam6",
                            parameterName: { label: "Order Id", id: "orderId" },
                            parameterValue: "",
                            errorText: ""
                        }
                    );
                }
                // In CTC and Rerate orders, users search for circuits by the contractCentral Url and spanNumber
                if ([Constants.ORDER_TYPES.CTC, Constants.ORDER_TYPES.RERATE].includes(order.orderType)) {
                    searchParameters.push(
                        {
                            id: "searchParam6",
                            parameterName: { label: "Contract Central URL", id: "contractCentralUrl" },
                            parameterValue: "",
                            errorText: ""
                        },
                        {
                            id: "searchParam7",
                            parameterName: { label: "Span Number", id: "spanNumber" },
                            parameterValue: "",
                            errorText: ""
                        }
                    );
                }
            } else if (order.orderType === Constants.ORDER_TYPES.CHANGE
                && Constants.INTERCONNECT_SERVICE_TYPES.includes(order.serviceType)) {
                searchParameters.push({
                    id: "searchParam4",
                    parameterName: { label: "LACP Provider", id: Constants.ATTRIBUTES.lacpProvider },
                    parameterValue: true,
                    errorText: ""
                });
            }
            return searchParameters;
        }
        return [];
    };

    /**
     * This function parses the searchParameters array and returns an object of search parameters entered by the user
     */
    static parseSearchParameters = (searchParameters) => {
        const searchParamsToReturn = {};
        const onlyNumbersRegex = /^\d+$/;
        searchParameters.forEach((searchParam) => {
            if (Object.keys(searchParam.parameterName).length > 0) {
                Object.assign(searchParamsToReturn, { [searchParam.parameterName.id]: searchParam.parameterValue });
                // When entering an order or circuitId, the user might only type in the actual number and not prepend
                // it with CIRCUIT- or ORDER-. We handle that case for them below
                if (onlyNumbersRegex.test(searchParam.parameterValue)) {
                    if (searchParam.parameterName.id === Constants.ATTRIBUTES.circuitDesignNumber) {
                        Object.assign(searchParamsToReturn,
                            { [searchParam.parameterName.id]: `${Constants.CIRCUIT_DELIMETER}${searchParam.parameterValue}` });
                    }
                    if (searchParam.parameterName.id === Constants.ATTRIBUTES.orderId) {
                        Object.assign(searchParamsToReturn,
                            { [searchParam.parameterName.id]: `${Constants.ORDER_DELIMETER}${searchParam.parameterValue}` });
                    }
                }
            }
        });
        // If either the contractCentralUrl or spanNumber are provided, we remove all other search parameters
        if (Object.keys(searchParamsToReturn).some(searchParam =>
            [Constants.ATTRIBUTES.contractCentralUrl, Constants.ATTRIBUTES.spanNumber].includes(searchParam))) {
            const newSearchParamsToReturn = {};
            Object.keys(HelperFunctions.deepClone(searchParamsToReturn))
                .filter(searchParamKey =>
                    [Constants.ATTRIBUTES.contractCentralUrl, Constants.ATTRIBUTES.spanNumber].includes(searchParamKey))
                .forEach(searchParamKey =>
                    Object.assign(
                        newSearchParamsToReturn,
                        { [searchParamKey]: searchParamsToReturn[searchParamKey] }
                    ));

            return newSearchParamsToReturn;
        }

        return searchParamsToReturn;
    };

    /**
     * This function add a new filter to the search params area
     */
    static addSearchParam = (evt, searchParameters, setSearchParameters) => {
        const newSearchParamId = `searchParam${searchParameters.length + 1}`;
        const updatedSearchParameters = searchParameters.concat({
            id: newSearchParamId,
            parameterName: {},
            parameterValue: "",
            errorText: ""
        });
        setSearchParameters(
            updatedSearchParameters
        );
    };

    /**
     * This function removes a search param
     */
    static removeSearchParam = (evt, searchParameters, setSearchParameters) => {
        const updatedSearchParameters = HelperFunctions.subtractSpecificObjectHelper(
            searchParameters,
            evt.target.id,
            Constants.DYNAMIC_INPUT_TYPES.searchParams
        );
        setSearchParameters(
            updatedSearchParameters
        );
    };

    /**
     * This function fetches every circuitDesign objects based on the query parameters so that the user can select
     * the circuitDesigns they want for adding as a custom component in positionmap
     */
    static getQueriedCircuitDesignObjects = async (
        searchParameters, order, siteNames, onlyActiveLifecycle, auth, setIsQueryForCircuitsInProgress,
        setQueriedCircuitDesignObjects, handleFlashBarMessagesFromChildTabs) => {
        setIsQueryForCircuitsInProgress(true);
        try {
            const finalInput = SearchHandler.parseSearchParameters(searchParameters);
            // We need to fetch only active circuits if that's the flag passed in. If the order type is
            // CTC or Rerate we do not include the lifecycle parameter because the query API will throw an error
            if (onlyActiveLifecycle && !!order
                && ![Constants.ORDER_TYPES.CTC, Constants.ORDER_TYPES.RERATE].includes(order.orderType)) {
                finalInput.lifeCycleStage = Constants.LIFECYCLE_STAGES.active;
            }
            if (!!order && Constants.PATH_CUSTOMER_FABRICS.includes(order.customerFabric)) {
                finalInput.fetchSpanProviderCircuitNames = true;
            }
            const response = await new FremontBackendClient().getCircuitDesignsBasedOffSearchParams(finalInput, auth);
            setQueriedCircuitDesignObjects(response.circuitDesigns);
            setIsQueryForCircuitsInProgress(false);
            handleFlashBarMessagesFromChildTabs(false, false, true);
        } catch (error) {
            handleFlashBarMessagesFromChildTabs(false, error, false);
            setIsQueryForCircuitsInProgress(false);
        }
    };

    /**
     * This function handles changes to the search parameters
     */
    static handleSearchParamChangeForSelect = (evt, searchParameters, setSearchParameters) => {
        const updatedSearchParameters = HelperFunctions.deepClone(searchParameters);
        updatedSearchParameters.map((searchParameterObject) => {
            if (searchParameterObject.id === evt.target.id) {
                return Object.assign(searchParameterObject, { parameterValue: evt.detail.selectedOption.label });
            }
            return searchParameterObject;
        });
        setSearchParameters(updatedSearchParameters);
    };

    /**
     * This function handles changes to the search parameters
     */
    static handleSearchParamChange = (evt, searchParameters, setSearchParameters) => {
        const updatedSearchParameters = HelperFunctions.deepClone(searchParameters);
        updatedSearchParameters.map((searchParameterObject) => {
            if (evt.target.id === Constants.ATTRIBUTES.lacpProvider
                && searchParameterObject.parameterName.id === evt.target.id) {
                return Object.assign(searchParameterObject, { parameterValue: evt.detail.selectedOption.value });
            }
            if (searchParameterObject.id === evt.target.id) {
                if (evt.detail.value !== undefined) {
                    return Object.assign(searchParameterObject, { parameterValue: evt.detail.value });
                }
                return Object.assign(searchParameterObject, { parameterName: evt.detail.selectedOption });
            }
            return searchParameterObject;
        });
        setSearchParameters(updatedSearchParameters);
    };
}

const SELECT_FILTERS = [Constants.ATTRIBUTES.serviceType, Constants.ATTRIBUTES.customerFabric,
    Constants.ATTRIBUTES.providerName, Constants.ATTRIBUTES.lacpProvider];

export const SearchInformation = props => (
    <div>
        { props.searchParameters.map(searchParameterObject => (
            <div key={searchParameterObject.id}>
                <Grid
                    gridDefinition={
                        [{ colspan: { default: 5 } }, { colspan: { default: 5 } }, { colspan: { default: 2 } }]
                    }
                >
                    <div>
                        { /* DO NOT USE `expandToViewport` for this select, it fails to show the dropdown */ }
                        <FremontSelect
                            id={searchParameterObject.id}
                            placeholder="Select attribute"
                            disabled={props.disabledFilterFields
                                .includes(searchParameterObject.parameterName.label)
                                || props.stageCompleted || props.isUpdateInProgress}
                            options={props.searchParameterOptions.filter(searchParameter =>
                                !props.searchParameters
                                    .map(searchParam => searchParam.parameterName.id).includes(
                                        searchParameter.id
                                    ))}
                            selectedOption={Object.keys(searchParameterObject.parameterName).length === 0
                                ? "" : searchParameterObject.parameterName}
                            onChange={(evt) => {
                                SearchHandler.handleSearchParamChange(
                                    evt, props.searchParameters, props.setSearchParameters
                                );
                            }}
                        />
                    </div>
                    {SELECT_FILTERS.includes(searchParameterObject.parameterName.id) ?
                        <div className="col-5">
                            {searchParameterObject.parameterName.id === Constants.ATTRIBUTES.serviceType &&
                                // DO NOT USE `expandToViewport` for this select, it fails to show the dropdown
                                <FremontSelect
                                    id={searchParameterObject.id}
                                    placeholder="Select a service"
                                    onChange={(evt) => {
                                        SearchHandler.handleSearchParamChangeForSelect(
                                            evt, props.searchParameters, props.setSearchParameters
                                        );
                                    }}
                                    disabled={props.disabledFilterInputs
                                        .includes(searchParameterObject.parameterName.label)
                                        || props.stageCompleted || props.isUpdateInProgress || props.customComponent}
                                    options={HelperFunctions.createSelectedOptions(
                                        Object.values(Constants.SERVICE_TYPES)
                                    )}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        searchParameterObject.parameterValue
                                    )}
                                />
                            }
                            {searchParameterObject.parameterName.id === Constants.ATTRIBUTES.customerFabric &&
                                // DO NOT USE `expandToViewport` for this select, it fails to show the dropdown
                                <FremontSelect
                                    id={searchParameterObject.id}
                                    placeholder="Select a Customer/Fabric"
                                    onChange={(evt) => {
                                        SearchHandler.handleSearchParamChangeForSelect(
                                            evt, props.searchParameters, props.setSearchParameters
                                        );
                                    }}
                                    disabled={props.disabledFilterInputs
                                        .includes(searchParameterObject.parameterName.label)
                                    || props.stageCompleted || props.isUpdateInProgress}
                                    options={HelperFunctions.createSelectedOptions(
                                        Constants.CONSUMABLE_CUSTOMER_FABRICS
                                    )}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        searchParameterObject.parameterValue
                                    )}
                                />
                            }
                            {searchParameterObject.parameterName.id === Constants.ATTRIBUTES.providerName &&
                                // DO NOT USE `expandToViewport` for this select, it fails to show the dropdown
                                <FremontSelect
                                    id={searchParameterObject.id}
                                    placeholder="Select a Provider"
                                    onChange={(evt) => {
                                        SearchHandler.handleSearchParamChangeForSelect(
                                            evt, props.searchParameters, props.setSearchParameters
                                        );
                                    }}
                                    disabled={props.disabledFilterInputs
                                        .includes(searchParameterObject.parameterName.label)
                                        || props.stageCompleted || props.isUpdateInProgress}
                                    options={props.providerOptions || []}
                                    filteringType="auto"
                                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        searchParameterObject.parameterValue
                                    )}
                                />
                            }
                            {(searchParameterObject.parameterName.id === Constants.ATTRIBUTES.lacpProvider
                                || searchParameterObject.parameterName.id === Constants.ATTRIBUTES.consumable) &&
                                // DO NOT USE `expandToViewport` for this select, it fails to show the dropdown
                                <FremontSelect
                                    id={searchParameterObject.parameterName.id}
                                    disabled={props.disabledFilterInputs
                                        .includes(searchParameterObject.parameterName.label)
                                    || props.stageCompleted || props.isUpdateInProgress}
                                    options={Constants.GENERAL_YES_NO_OPTIONS}
                                    selectedOption={HelperFunctions.booleanToYesNoSelectedOption(
                                        searchParameterObject.parameterValue
                                    )}
                                    onChange={(evt) => {
                                        SearchHandler.handleSearchParamChange(
                                            evt, props.searchParameters, props.setSearchParameters
                                        );
                                    }}
                                />
                            }
                        </div>
                        :
                        <div className="col-5">
                            <FremontInput
                                id={searchParameterObject.id}
                                placeholder="Enter value"
                                disabled={props.disabledFilterInputs
                                    .includes(searchParameterObject.parameterName.label)
                                    || props.stageCompleted || props.isUpdateInProgress}
                                value={searchParameterObject.parameterValue}
                                onInput={(evt) => {
                                    SearchHandler.handleSearchParamChange(
                                        evt, props.searchParameters, props.setSearchParameters
                                    );
                                }}
                            />
                        </div>
                    }
                    <div>
                        <FremontButton
                            id={searchParameterObject.id}
                            disabled={props.disabledFilterFields
                                .includes(searchParameterObject.parameterName.label)
                                || props.stageCompleted || props.isUpdateInProgress}
                            iconName="close"
                            variant="normal"
                            onClick={evt => SearchHandler.removeSearchParam(
                                evt, props.searchParameters, props.setSearchParameters
                            )}
                        />
                    </div>
                </Grid>
            </div>
        ))}
        {/* Conditional statements which evaluate to true also evaluate to "1" when add/subtracted. */}
        <Box padding={{ top: "xs", bottom: "xs" }}>
            <FremontButton
                disabled={props.searchParameters.length ===
                ((props.searchParameterOptions.length + props.disabledFilterFields.length) - (props.order !== null))
                    || props.stageCompleted || props.isUpdateInProgress}
                iconName="add-plus"
                variant="primary"
                onClick={evt => SearchHandler.addSearchParam(
                    evt, props.searchParameters, props.setSearchParameters
                )}
            >
                Add Filter
            </FremontButton>
        </Box>
        <hr/>
        <Box padding={{ top: "xxs" }}>
            <FormField>
                <FremontButton
                    disabled={props.disableSearchButton}
                    loading={props.isQueryInProgress || props.isUpdateInProgress}
                    iconName="search"
                    variant="primary"
                    onClick={props.getQueriedObjects}
                >
                    Start Search
                </FremontButton>
            </FormField>
        </Box>
        <br/>
    </div>
);