import React from "react";
import { Box, ColumnLayout, Link } from "@amzn/awsui-components-react";
import { LinkServiceExpandableSection } from "../components/CommonComponents";
import Constants from "../utils/Constants";

// TODO fix this css approach once LinkService code has been moved out of FremontStaticWebsite and has its
// own index.css
const style = {
    noVerticalPaddingOrMargin: {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0
    },
    leftPaddingOnly: {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 41
    }
};

const deviceNameInterfaceName = `<device-name>_<interface-name>`;

/* eslint-disable react/no-unescaped-entities */
const SearchInstructionsComponent = () => (
    <LinkServiceExpandableSection
        // expanded TODO set from cookie
        headerText="Search Instructions"
    >
        <div>
            <Box>
                Link search is <b>case-insensitive</b>. Trailing spaces and tabs should be handled gracefully.
            </Box>
            <Box>
                Search terms can combined by using the <b>AND</b> keyword. If you do not provide any search term, such
                as "<code>pp:</code>", we will assume you are using the default search, which is on
                the "<code>end:</code>" field.
            </Box>
            <Box>
                Regex search should start with <b>/</b> and end with <b>/</b>. Ex. - <b>pp</b>:/lhr-pp3(1|2|3)x/
            </Box>
            <Box padding={{ left: "m" }}>
                A useful regex search is to wrap an identifier in two wildcards.
                Ex. - <b>fibreserviceid</b>:/.*FBDK/1063375/002//ZYO.*/
            </Box>
            <Box>
                Supplementary search terms cannot be used on their own. They must be combined with at least
                one primary search term using the <b>AND</b> keyword. Wildcard search is not supported for
                supplementary terms.
            </Box>
            <Box>
                Link tables will automatically expand if any links have been found as a part of the search.
            </Box>
            <Box>
                See examples of how to translate your legacy search into Fremont Search
                <Link
                    external
                    href="https://w.amazon.com/bin/view/Networking/NEST/Mango/Documentation/UI#HHowtoTranslateYourSearchToday3F"
                >
                    &nbsp;here.
                </Link>
                <Box>
                    <b> Note: </b>
                    <p style={{ display: "inline" }}> &bull; For this search term:
                    "lhr53-wdm-pp1-s5_CH-1-10-N AND lifecycle:OPERATIONAL" the supplementary search term act
                    as a filter on the primary search term (END TRUNK PORT), and not on the
                    other links fetched as a part of the hierarchy.
                    </p>
                    <p style={style.leftPaddingOnly} > &bull; Regex and Wild Card Search on
                        <b> Dark Fiber Handoff </b> value having special characters such as : "()", "[]", ","
                        etc. will either produce no result or throw an error.
                    </p>
                </Box>
            </Box>
        </div>
        <ColumnLayout columns={2}>
            <div>
                <h3>Primary Search Terms</h3>
                <b>dc</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        Data center for the link. Searches with leading zeroes have same results, i.e., atl50 and atl050
                        would give the same results.
                    </li>
                    <li>Wildcard search is not supported.</li>
                    <li>Regex search is not supported.</li>
                    <li>Example Search:</li>
                    <ul>
                        <li><b>dc</b>:atl50</li>
                        <li><b>dc</b>:atl50 AND <b>dc</b>:atl58</li>
                    </ul>
                </ul>
                <b>end</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        User-friendly name of the link end. One link has exactly two link ends.
                        For Amazon owned link ends, it will be the <code>{deviceNameInterfaceName}</code>.
                        For third-party link ends the format may not be consistent.
                    </li>
                    <li>Wildcard search is supported.</li>
                    <li>Regex search is supported.</li>
                    <li>Example Searches (notice "<code>end:</code>" is not required):</li>
                    <ul>
                        <li>pdx1-br-dcr-r202_xe-11/2/5</li>
                        <li>akl51-op-fni-r2_xe-0/0/18:2</li>
                        <li>atl50-wdm-pp8-s3_och-1-4</li>
                        <li>iad89-br-bfb-f1-b6-t1-r2*</li>
                        <li>atl50-br-fnc-f1-b1-t1-r14_jrp7-4 AND atl58-br-fnc-f1-b1-t1-r14_jrp7-4</li>
                        <li>pdx1-br-dcr-r202_xe-11/2/5</li>
                        <li>/atl50-br-fnc-f1-b1-t1-r(14|15|16)_jrp8-(3|4)/</li>
                    </ul>
                </ul>
                <b>fibreid</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        Fibre ID defined during the Fibre link creation process.
                    </li>
                    <li>Wildcard search is supported.</li>
                    <li>Regex search is supported.</li>
                    <li>Example Searches:</li>
                    <ul>
                        <li><b>fibreid</b>:ahn400-gsp400*</li>
                    </ul>
                </ul>
                <b>fibreinventoryid</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        Unique ID of the Fiber Span record in Styx.
                        A search on this will return all fibers pairs related to the particular fiber span.
                    </li>
                    <li>Wildcard search is not supported.</li>
                    <li>Regex search is not supported.</li>
                    <li>Example Searches:</li>
                    <ul>
                        <li><b>fibreinventoryid</b>:a78Dm000000CjZKIA0</li>
                    </ul>
                </ul>
                <b>fibrepairid</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        Unique ID of the Fiber Pair record in Styx.
                    </li>
                    <li>Wildcard search is not supported.</li>
                    <li>Regex search is not supported.</li>
                    <li>Example Searches:</li>
                    <ul>
                        <li><b>fibrepairid</b>:a77Dm000000H2E2IAK</li>
                    </ul>
                </ul>
                <b>fibreserviceid</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        Fibre Service attribute of fibre links that is provided by third party vendors.
                    </li>
                    <li>Wildcard search is supported.</li>
                    <li>Regex search is supported.</li>
                    <li>Example Search:</li>
                    <ul>
                        <li><b>fibreserviceid</b>:FBDK/1632041/009/ZFS</li>
                        <li><b>fibreserviceid</b>:F19L-0091129 CID: FBDK/1240246//ZFS</li>
                        {/* Using the HTML character codes so that the curly brackets render as expected */}
                        <li><b>fibreserviceid</b>:/002(0|1)-[0-9]&#123;4&#125;-0001/</li>
                        <li><b>fibreserviceid</b>:/.*FBDK/1063375/002//ZYO.*/</li>

                    </ul>
                </ul>
                <b>lh</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        Long Haul System Identifier
                    </li>
                    <li>Wildcard search is not supported.</li>
                    <li>Regex search is supported.</li>
                    <li>Example Search:</li>
                    <ul>
                        <li><b>lh</b>:l022</li>
                        <li><b>lh</b>:/l02[0-9]&#123;1&#125;/</li>
                    </ul>
                </ul>
                <b>mcmid</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        MCM Id. A link can be searched by the MCM ID if this data
                        was uploaded in the "cm_or_tt_url" field.
                    </li>
                    <li>Wildcard search is not supported.</li>
                    <li>Regex search is not supported.</li>
                    <li>Search using the CM Id only, not the URL.</li>
                    <li>Example Search:</li>
                    <ul>
                        <li><b>mcmid</b>:MCM-12345678</li>
                        <li><b>mcmid</b>:12345678</li>
                    </ul>
                </ul>
                <b>ospsite</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        One site of an outside plant (OSP) passive to passive link inside of LinkService.
                        This attribute will only be populated for passive to passive records that were created
                        directly from Styx.
                    </li>
                    <li>
                        The site codes searched for here <b>will always have three letters and three numbers</b>.
                        Leading zeroes must be included in the search for it to work properly.
                    </li>
                    <li>Wildcard search is not supported.</li>
                    <li>Regex search is not supported.</li>
                    <li>Example Searches:</li>
                    <ul>
                        <li><b>ospsite</b>:hio050 AND <b>ospsite</b>:hio051</li>
                    </ul>
                </ul>
                <b>pp</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        PP system. This field concatenates a cluster and a pp system number with a hypen. Results shown
                        may include inter and intra region links.
                    </li>
                    <li>
                        Wildcard search is supported, but the support is limited. You must provide the
                        region/cluster/pop along with a pp-system number.
                    </li>
                    <li>Regex search is supported.</li>
                    <li>Example Searches:</li>
                    <ul>
                        <li><b>pp</b>:atl-pp6*</li>
                        <li><b>pp</b>:lhr-pp32*</li>
                        <li><b>pp</b>:/lhr-pp3(1|2|3)x/</li>
                    </ul>
                </ul>
                <b>spanname</b>
                <ul style={style.noVerticalPaddingOrMargin}>
                    <li>
                        Human readable name of the Fiber Span record in Styx as chosen by Metro Fiber.
                        A search on this will return all fiber pairs related to the particular fiber span.
                    </li>
                    <li>Wildcard search is not supported.</li>
                    <li>Regex search is supported.</li>
                    <li>Example Searches:</li>
                    <ul>
                        <li><b>spanname</b>:Span - 8 - 12 - IAD420 - IAD163 - PO2</li>
                        <li><b>spanname</b>:/Span - 8 - 12 - IAD420 - [A-Z]&#123;3&#125;[0-9]&#123;3&#125; - PO2/</li>
                    </ul>
                </ul>
            </div>
            <div>
                <div>
                    <h3>Supplementary Search Terms</h3>
                    <b>lifecycle</b>
                    <ul style={style.noVerticalPaddingOrMargin}>
                        <li>
                            Lifecycle of the link. Possible options are:
                        </li>
                        <ul>
                            {
                                Object.values(Constants.LIFECYCLE_STATES)
                                    .map(lifecycle => <li key={lifecycle}>{lifecycle}</li>)
                            }
                        </ul>
                        <li>Example Search:</li>
                        <ul>
                            <li>lhr53-wdm-pp1-s5_CH-1-10-N AND <b>lifecycle</b>:OPERATIONAL</li>
                            <b>Note:</b> "lifecycle" filter is <b>not</b> applied to the links fetched as a part of this
                            hierarchy.
                        </ul>
                    </ul>
                    <b>linktype</b>
                    <ul style={style.noVerticalPaddingOrMargin}>
                        <li>
                            Type of the link. Possible options are:
                        </li>
                        <ul>
                            {
                                Object.values(Constants.LINK_TYPES).map(linkType =>
                                    <li key={linkType}>{linkType.replace(/\s+/g, "")}</li>) // Remove spaces
                            }
                        </ul>
                        <li>Example Search:</li>
                        <ul>
                            <li><b>lh</b>:l021 AND <b>linktype</b>:MuxToMux</li>
                        </ul>
                    </ul>
                    <b>wavelength</b>
                    <ul style={style.noVerticalPaddingOrMargin}>
                        <li>
                            Wavelength of light along the link
                        </li>
                        <li>Example Search:</li>
                        <ul>
                            <li>pdx1-wdm-pp62-s9* AND <b>wavelength</b>:1540.95</li>
                        </ul>
                    </ul>
                </div>
            </div>
        </ColumnLayout>
    </LinkServiceExpandableSection>
);

export default SearchInstructionsComponent;