import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    Table,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontAlert,
    FremontButton,
    FremontExpandableSection,
    FremontToggle,
    ComponentConstants,
    useCollectionDefault
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

/**
 * This function determines what placeholder text should be displayed for the ASN field on an order form or edit page
 * @param allFieldsDisabled
 * @param providerName
 * @param asnsLoading
 * @param asnOptions
 * @returns {string}
 */
export function determineAsnPlaceholderText(allFieldsDisabled, providerName, asnsLoading, asnOptions) {
    if (allFieldsDisabled) {
        return "";
    }
    if (!asnsLoading && asnOptions.length === 0 && providerName) {
        return Constants.PLACEHOLDER_TEXTS.noAsnsForProvider;
    }
    if (providerName) {
        return "Enter an ASN";
    }
    return "Select a Provider first";
}

export const CircuitMissingFields = (props) => {
    const filteredCircuitItems = props.circuitItems.filter(circuit =>
        !!circuit.requiredFieldsCompletedMap[props.stageName] &&
        circuit.requiredFieldsCompletedMap[props.stageName] !== Constants.ORDER_MISSING_FIELDS);
    return filteredCircuitItems.length !== 0 &&
        <FremontAlert dismissible>
            <FremontExpandableSection header="Missing Circuit Fields">
                <ul>
                    {props.circuitItems.map(circuit => circuit.requiredFieldsCompletedMap[props.stageName]
                        && (
                            <li key={circuit.circuitDesignNumber}>
                                {`${circuit.circuitDesignNumber}: ${circuit.requiredFieldsCompletedMap[props.stageName]}`}
                            </li>
                        ))
                    }
                </ul>
            </FremontExpandableSection>
        </FremontAlert>;
};

export const StageHeader = props => (
    <Box>
        <SpaceBetween direction="vertical" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
            <div>
                <Box float="left" variant="h2">
                    {Constants.BACKEND_TO_FRONTEND_STAGE_MAP[props.stageName]}
                </Box>
                <Box float="right">
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        {props.showTemplateGeneratorModal &&
                            <FremontButton
                                id={props.stageName}
                                iconName="add-plus"
                                variant="primary"
                                onClick={props.showTemplateGeneratorModal}
                                disabled={props.disableEditButton}
                            >
                                Generate Template
                            </FremontButton>
                        }
                        {props.showAttachmentModal &&
                            <FremontButton
                                id={props.stageName}
                                iconName="add-plus"
                                variant="primary"
                                onClick={props.showAttachmentModal}
                                disabled={props.disableAttachmentButton}
                            >
                                Add Attachments
                            </FremontButton>
                        }
                        {props.handleCompleteStage &&
                            <Box padding={{ top: "xs" }}>
                                <FremontToggle
                                    id={props.stageName}
                                    onChange={props.handleCompleteStage}
                                    checked={props.hasStageBeenCompleted}
                                    className="action-button"
                                    disabled={props.disableEditButton}
                                >
                                    {props.completeStageMessage}
                                </FremontToggle>
                            </Box>
                        }
                        {!props.isOrderAcceptance
                        && props.handleStageEditClick
                        && (!props.showAddCircuits || props.order.circuitQuantity) &&
                            <FremontButton
                                id={props.stageName}
                                disabled={props.disableEditButton}
                                iconName="edit"
                                variant="primary"
                                onClick={props.handleStageEditClick}
                            />
                        }
                        {!props.order.circuitQuantity && props.showAddCircuits &&
                            <FremontButton
                                id={props.stageName}
                                disabled={props.disableEditButton}
                                variant="primary"
                                onClick={props.handleStageEditClick}
                            >
                            Add Circuits
                            </FremontButton>
                        }
                        {props.handleStageSubmitForApproval &&
                            <FremontButton
                                id={props.stageName}
                                disabled={props.disableSubmitForApproval}
                                loading={props.loadingSubmitForApproval}
                                variant="primary"
                                onClick={props.handleStageSubmitForApproval}
                            >
                                {props.loadingSubmitForApproval ? "Submitting For Approval..." :
                                    "Submit For Approval"}
                            </FremontButton>
                        }
                    </SpaceBetween>
                </Box>
            </div>
            <Box variant="h4">
                <Box float="left">
                    {HelperFunctions.showStageStatusWithBlocker(props.order
                        .stageStatusMap[props.stageName])}
                </Box>
                <Box float="right">
                    {HelperFunctions.getStageCompletionDate(props.stageName, props.order)}
                </Box>
            </Box>
            <div>
                {props.order.workflow.stages[props.stageName].previousStages !== null && (
                    <Box float="left">
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            {props.order.workflow.stages[props.stageName].previousStages &&
                            props.order.workflow.stages[props.stageName].previousStages.map((prevStage) => {
                                let selectedPrevStage = prevStage;
                                if (Constants.STAGE_NAMES.workOrderExecution === prevStage &&
                                    HelperFunctions.isProviderAmazonInternal(props.order.providerName)) {
                                    [selectedPrevStage] = props.order.workflow.stages[
                                        Constants.STAGE_NAMES.workOrderExecution].previousStages;
                                }
                                return (
                                    <FremontButton
                                        key={`${selectedPrevStage}${props.stageName}`}
                                        iconName="angle-left"
                                        variant="normal"
                                        onClick={() =>
                                            props.goToComponentAction(
                                                Constants.DEPENDENCY_LINK_REFERENCES[selectedPrevStage],
                                                props.order.workflow, selectedPrevStage
                                            )
                                        }
                                    >
                                        {Constants.BACKEND_TO_FRONTEND_STAGE_MAP[selectedPrevStage]}
                                    </FremontButton>
                                );
                            })}
                        </SpaceBetween>
                    </Box>)
                }
                {props.order.workflow.stages[props.stageName].nextStages !== null
                && !props.order.workflow.stages[props.stageName].nextStages
                    .includes(Constants.STAGE_NAMES.completeOrder) &&
                (
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            {props.order.workflow.stages[props.stageName].nextStages.map((nextStage) => {
                                let selectedNextStage = nextStage;
                                if (Constants.STAGE_NAMES.workOrderExecution === nextStage
                                    && HelperFunctions.isProviderAmazonInternal(props.order.providerName)) {
                                    [selectedNextStage] = props.order.workflow.stages[
                                        Constants.STAGE_NAMES.workOrderExecution].nextStages;
                                }
                                return (
                                    <FremontButton
                                        key={`${selectedNextStage}${props.stageName}`}
                                        iconName="angle-right"
                                        variant="normal"
                                        onClick={() =>
                                            props.goToComponentAction(Constants.DEPENDENCY_LINK_REFERENCES[
                                                selectedNextStage], props.order.workflow, selectedNextStage)}
                                    >
                                        {Constants.BACKEND_TO_FRONTEND_STAGE_MAP[selectedNextStage]}
                                    </FremontButton>
                                );
                            })}
                        </SpaceBetween>
                    </Box>
                )
                }
            </div>
            <div>
                {props.order.requiredFieldsCompletedMap[props.stageName] &&
                    <ColumnLayout columns={props.circuitItems && props.circuitItems.filter(circuit =>
                        circuit.requiredFieldsCompletedMap[props.stageName] !== Constants.ORDER_MISSING_FIELDS)
                        .some(circuit => circuit.requiredFieldsCompletedMap[props.stageName]) ? 2 : 1}
                    >
                        <FremontAlert dismissible>
                            <FremontExpandableSection header="Missing Order Fields">
                                {props.circuitItems && props.circuitItems.filter(circuit =>
                                    circuit.requiredFieldsCompletedMap[props.stageName]
                                !== Constants.ORDER_MISSING_FIELDS).length !== 0 ?
                                    props.order.requiredFieldsCompletedMap[props.stageName] :
                                    props.order.requiredFieldsCompletedMap[props.stageName]
                                        .replace(Constants.CIRCUITS_MISSING_FIELDS, "")
                                }
                            </FremontExpandableSection>
                        </FremontAlert>
                        {props.circuitItems &&
                            <CircuitMissingFields
                                stageName={props.stageName}
                                circuitItems={props.circuitItems}
                            />
                        }
                    </ColumnLayout>
                }
            </div>
        </SpaceBetween>
    </Box>
);

export const TableDisplayMode = props => (
    <FremontExpandableSection
        variant="container"
        header={
            <Header variant="h2">
                {!props.unitTable ? "Circuits" : "Units"}
            </Header>
        }
        defaultExpanded={!HelperFunctions.isStageCompleted(props.order.stageStatusMap[props.stageName])}
    >
        <div>
            <div className="col-12">
                {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
                <TableDisplayModeTable
                    columnDefinitions={props.columnDefinitions}
                    circuitItems={props.circuitItems}
                    unitItems={props.unitItems}
                    isDataLoaded={props.isDataLoaded}
                    resizableColumns
                    customEmptyMessage={props.customEmptyMessage}
                />
            </div>
        </div>
    </FremontExpandableSection>
);

export const TableDisplayModeTable = (props) => {
    const { items, collectionProps } = useCollectionDefault(props.circuitItems);

    return (
        <Table
            {...collectionProps}
            columnDefinitions={props.columnDefinitions}
            header={
                <Header variant="h2">
                    {props.header}
                </Header>
            }
            items={items}
            resizableColumns
            wrapLines
            loading={!props.isDataLoaded}
            loadingText={Constants.STATUS_TEXTS.loadingComponents}
            empty={
                <Box textAlign="center">
                    <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                        <b>
                            {!props.customEmptyMessage
                                ? Constants.EMPTY_TABLE_MESSAGES.noCircuits
                                : props.customEmptyMessage}
                        </b>
                    </Box>
                </Box>
            }
        />
    );
};

export const StageDisplayMode = props => (
    <div className="fremont-stage-spacing">
        {/* TODO: look into using the useRef hook */}
        <div ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
            <Container
                header={
                    <StageHeader
                        order={props.order}
                        stageName={props.stageName}
                        showAttachmentModal={props.showAttachmentModal}
                        disableAttachmentButton={props.disableAttachmentButton}
                        disableEditButton={props.disableEditButton}
                        handleStageEditClick={props.handleStageEditClick}
                        goToComponentAction={props.goToComponentAction}
                        circuitItems={props.circuitItems}
                        showTemplateGeneratorModal={props.showTemplateGeneratorModal}
                        handleCompleteStage={props.handleCompleteStage}
                        hasStageBeenCompleted={props.hasStageBeenCompleted}
                        completeStageMessage={props.completeStageMessage}
                    />
                }
            >
                {props.content}
            </Container>
        </div>
    </div>
);

export const TableEditMode = props => (
    <FremontExpandableSection
        variant="container"
        header={
            <Header variant="h2">
                {!props.unitTable ? "Circuits" : "Units"}
            </Header>
        }
        defaultExpanded
    >
        <div>
            <div className="col-12">
                {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
                <TableEditModeTable
                    columnDefinitions={props.columnDefinitions}
                    circuitItems={props.circuitItems}
                    handleSelectedFromTable={props.handleSelectedFromTable}
                    unitTable={props.unitTable || props.multiTable}
                    customEmptyMessage={props.customEmptyMessage}
                    massUpdateSelectedCircuitDesignIds={props.massUpdateSelectedCircuitDesignIds}
                />
            </div>
        </div>
    </FremontExpandableSection>
);

export const TableEditModeTable = (props) => {
    const { items, collectionProps } = useCollectionDefault(props.circuitItems);

    return (
        <Table
            {...collectionProps}
            id={props.id}
            columnDefinitions={props.columnDefinitions}
            header={
                <Header variant="h2">
                    {props.header}
                </Header>
            }
            items={items}
            isItemDisabled={item => item.allFieldsDisabled}
            resizableColumns
            wrapLines
            loading={props.loading ? props.loading : false}
            loadingText={Constants.STATUS_TEXTS.loadingOptions}
            empty={
                <Box textAlign="center">
                    <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                        <b>
                            {!props.customEmptyMessage
                                ? Constants.EMPTY_TABLE_MESSAGES.noCircuits
                                : props.customEmptyMessage}
                        </b>
                    </Box>
                </Box>
            }
            selectionType="multi"
            trackBy={!props.unitTable ? "circuitDesignId" : "groupingId"}
            onSelectionChange={props.handleSelectedFromTable}
            selectedItems={props.circuitItems.filter(
                circuit => props.massUpdateSelectedCircuitDesignIds.includes(!props.unitTable ?
                    circuit.circuitDesignId : circuit.groupingId)
            )}
        />
    );
};

export const StageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateStageInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                variant="primary"
                                id={`${props.stageName}StageSubmitButton`}
                                disabled={!!props.isUpdateStageDisabled}
                                loading={props.isUpdateStageInProgress}
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateStageInProgress ?
                                    Constants.SUBMISSION_STRINGS.submitInProgress :
                                    Constants.SUBMISSION_STRINGS.readyToSubmit}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    {Constants.BACKEND_TO_FRONTEND_STAGE_MAP[props.stageName]}
                    {HelperFunctions.showStageStatusWithBlocker(props.order.stageStatusMap[props.stageName])}
                </Header>
            }
        >
            {props.content}
        </Container>
    </div>
);
