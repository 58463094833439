import React, { Component } from "react";
import {
    ConfirmationModal,
    FremontAlert
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import {
    StageDisplayMode,
    TableDisplayMode,
    StageEditMode,
    TableEditMode
} from "order/OrderCommonComponents";
import OrderValidation from "order/OrderValidation";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import generateCircuitActivationColumnDefinitions from "./CircuitActivationInformation";

export default class CircuitActivationHandler extends Component {
    state = {
        isEditClicked: false,
        hasBeenSubmittedOnce: false,
        isUpdateStageInProgress: false,
        updatedCircuitDesignObjects: [],
        allFieldsDisabled: false,
        massUpdateSelectedCircuitDesignIds: [],
        isModalVisible: false
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * Handle any edits to the order
     * @param evt
     */
    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(
            this.state.massUpdateSelectedCircuitDesignIds, input, circuitDesignOutput.circuitDesignObjects
        );
        this.setState({ updatedCircuitDesignObjects: output });
    };

    handleSelectedFromTable = (evt) => {
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    /**
     * This method returns an array of objects that are rendered inside of the business developer submit stage table
     */
    generateCircuitItems = () => {
        const circuitItemsObjects = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.allFieldsDisabled
        );

        if (circuitItemsObjects.static.length > 0) {
            circuitItemsObjects.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.COMPONENT_NAMES.providerCircuitA]: HelperFunctions.getProviderCircuitDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.providerCircuitA,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    )
                }));

            if (circuitItemsObjects.dynamic.length > 0) {
                circuitItemsObjects.dynamic.forEach(dynamicCircuitDesign =>
                    Object.assign(dynamicCircuitDesign, {
                        [Constants.COMPONENT_NAMES.providerCircuitA]:
                            dynamicCircuitDesign[Constants.COMPONENT_NAMES.providerCircuitA]
                    }));
            }
        }

        return circuitItemsObjects;
    }

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects)
            .map(circuitDesign => Object.assign(circuitDesign, {
                [Constants.COMPONENT_NAMES.providerCircuitA]: HelperFunctions.getProviderCircuitDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.providerCircuitA,
                    this.props.order,
                    this.props.componentIdToObjectMap
                ),
                errorTexts: {}
            }));
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedCircuitDesignIds: [],
            hasBeenSubmittedOnce: false,
            updatedCircuitDesignObjects,
            allFieldsDisabled: false
        });
    }

    generateCircuitsWithChanges = () => {
        const circuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);

        return updatedCircuitDesignObjects
            .filter((updatedCircuitDesign) => {
                const matchingCircuitDesign = circuitDesignObjects.find(circuitDesign =>
                    circuitDesign.circuitDesignId === updatedCircuitDesign.circuitDesignId);
                return matchingCircuitDesign[Constants.ATTRIBUTES.circuitActivationStatus]
                    !== updatedCircuitDesign[Constants.ATTRIBUTES.circuitActivationStatus];
            });
    }

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            isUpdateStageInProgress: true,
            allFieldsDisabled: true
        });

        const circuitsWithChanges = this.generateCircuitsWithChanges();
        if (circuitsWithChanges.length > 0) {
            if (circuitsWithChanges.some(circuit =>
                circuit[Constants.ATTRIBUTES.circuitActivationStatus]
                    === Constants.STATUS.incomplete)) {
                this.props.handleFlashBarMessagesFromChildTabs(
                    false,
                    { message: "Cannot deactivate activated circuit." },
                    false
                );
                this.setState({ isUpdateStageInProgress: false });
            } else if (circuitsWithChanges.some(circuitDesign =>
                Object.values(circuitDesign.errorTexts).some(errorText => errorText))) {
                this.setState({
                    isUpdateStageInProgress: false
                });
            } else {
                this.setState({
                    isModalVisible: true
                });
            }
        } else {
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    }

    submitCircuits = async () => {
        try {
            // only update circuits that need to be updated (otherwise making expensive backend calls for no reason)
            const circuitsToUpdate = HelperFunctions.createNewApiObjectsCircuitWrapperForStage(
                this.props.circuitDesignObjects, this.state.updatedCircuitDesignObjects
            );
            if (circuitsToUpdate.length > 0) {
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }

            // Here we call a helper function which updates all data related to the order
            // and loads new component info as well
            await this.props.loadData(true, true);

            // Display success message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false
            );
            this.setState({
                isEditClicked: false,
                isUpdateStageInProgress: false,
                allFieldsDisabled: false,
                isModalVisible: false
            });
        } catch (error) {
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isEditClicked: false,
                isUpdateStageInProgress: false,
                allFieldsDisabled: false,
                isModalVisible: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    }

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.circuitActivation}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.generateCircuitItems().static.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    content={
                        <TableDisplayMode
                            order={this.props.order}
                            stageName={Constants.STAGE_NAMES.circuitActivation}
                            circuitItems={this.generateCircuitItems().static}
                            columnDefinitions={generateCircuitActivationColumnDefinitions(this.props.order,
                                this.props.span)}
                            isDataLoaded={this.props.isDataLoaded}
                        />
                    }
                />
                :
                <div>
                    <StageEditMode
                        order={this.props.order}
                        stageName={Constants.STAGE_NAMES.circuitActivation}
                        handleStageEditClick={this.handleStageEditClick}
                        handleStageSubmit={this.handleStageSubmit}
                        isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                        content={
                            <TableEditMode
                                circuitItems={this.generateCircuitItems().dynamic}
                                columnDefinitions={generateCircuitActivationColumnDefinitions(this.props.order,
                                    this.props.span)}
                                handleSelectedFromTable={this.handleSelectedFromTable}
                                massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                            />
                        }
                    />
                    <ConfirmationModal
                        isVisible={this.state.isModalVisible}
                        loading={false}
                        header="Circuit Activation Confirmation"
                        description={
                            <FremontAlert type="warning">
                                You are about to activate a circuit. Once a circuit has been activated, the
                                circuit will no longer be editable and you can no longer cancel the order.
                                If you still have data to edit, hit cancel and modify that data.
                            </FremontAlert>
                        }
                        hideModal={() => this.setState({
                            isUpdateStageInProgress: false,
                            isModalVisible: false,
                            allFieldsDisabled: false
                        })}
                        cancelButtonText="Cancel"
                        primaryButtonText="Activate Circuits"
                        onClickFunction={this.submitCircuits}
                    />
                </div>
        );
    }
}

