import LinkServiceBackendClient from "../../../mango/js/common/LinkServiceBackendClient";

export default class CutsheetHelper {
    linkServiceBackendClient = new LinkServiceBackendClient();

    async getCutsheetById(cutsheetId) {
        const getCutsheetResponse = await this.linkServiceBackendClient.getCutsheet(cutsheetId);
        return this.updateCutsheetFieldTypes(getCutsheetResponse.cutsheet);
    }

    async getSpecificCutsheetVersionById(cutsheetId, cutsheetVersion) {
        // GetCutsheetVersions returns a list of cutsheet objects. We only want a single
        // version so we specify the maxResults as 1. Since we do this, we can safely extract the first
        // element from the returned list
        const getCutsheetVersionsResponse = await this.linkServiceBackendClient.getCutsheetVersions(
            cutsheetId,
            parseInt(cutsheetVersion, 10),
            1
        );
        return this.updateCutsheetFieldTypes(getCutsheetVersionsResponse.cutsheetList[0]);
    }

    /**
     * This method updates the cutsheetVersion to be an integer instead of a string
     * @param cutsheet
     * @returns {*&{cutsheetVersion: number}}
     */
    updateCutsheetFieldTypes = cutsheet => ({
        ...cutsheet,
        cutsheetVersion: parseInt(cutsheet.cutsheetVersion, 10)
    });
}