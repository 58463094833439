import React, { Component } from "react";
import {
    appendConsumedSpanProviderCircuitIdsToMeta,
    generateSelectCircuitsColumnDefinitions,
    SelectCircuitsStage,
    SpanDecomToggle
} from "order/stages/decom/SelectCircuitsInformation";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import {
    SearchInformation,
    SearchHandler
} from "common/SearchHandler";
import {
    StageDisplayMode,
    TableDisplayMode,
    StageEditMode
} from "order/OrderCommonComponents";
import OrderReasonNoteModal from "order/OrderReasonNoteModal";
import OrderValidation from "order/OrderValidation";

export default class SelectCircuitsHandler extends Component {
    state = {
        isUpdateStageInProgress: false,
        hasBeenSubmittedOnce: false,
        selectedCircuitDesignObjects: HelperFunctions.deepClone(this.props.circuitDesignObjects),
        queriedCircuitDesignObjects: [],
        isQueryForCircuitsInProgress: false,
        lacpProvider: HelperFunctions.parseBoolean(this.props.order.lacpProvider),
        lacpProviderError:
            (Constants.INTERCONNECT_SERVICE_TYPES.includes(this.props.order.serviceType)
                && Constants.ORDER_TYPES.CHANGE === this.props.order.orderType
                && !this.props.order.lacpProvider) ? Constants.ERROR_STRINGS.blankInput : "",
        searchParameters: SearchHandler.generateSearchParameters(
            SearchHandler.TYPES.SelectCircuitsHandler, this.props.order, this.props.siteNames
        ),
        allFieldsDisabled: false,
        isEditClicked: false,
        isAddReasonClicked: false,
        decomNoteBody: "",
        noteToCreateBody: "",
        errorText: "",
        updateCreateSpanDecomOrdersOnTrigger: this.props.order.createSpanDecomOrdersOnTrigger || false
    };

    componentDidMount = async () => {
        try {
            if ([Constants.ORDER_TYPES.CHANGE, Constants.ORDER_TYPES.DECOMMISSION].includes(this.props.order.orderType)
                && Constants.PATH_CUSTOMER_FABRICS.includes(this.props.order.customerFabric)) {
                const response = await OrderValidation.fetchLatestRejectionNote(
                    this.FremontBackendClient,
                    this.props.order.noteIdList,
                    this.props.auth,
                    Constants.SPAN_DECOM_REASON
                );
                this.setState({
                    decomNoteBody: response === undefined ? "" : response.noteBody
                });
            }
        } catch (error) {
            // This is used for showing the flashbar error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    /**
     * This helps update the searchParameters state variable when siteNames update
     * @param prevProps
     */
    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.siteNames) !== JSON.stringify(this.props.siteNames)) {
            this.setState({
                searchParameters: SearchHandler.generateSearchParameters(
                    SearchHandler.TYPES.SelectCircuitsHandler, this.props.order, this.props.siteNames
                )
            });
        }
    }

    getQueriedCircuitDesignObjects = async () => {
        await SearchHandler.getQueriedCircuitDesignObjects(
            this.state.searchParameters, this.props.order, this.props.siteNames, true,
            this.props.auth,
            (isQueryForCircuitsInProgress) => {
                this.setState({ isQueryForCircuitsInProgress });
            },
            (queriedCircuitDesignObjects) => {
                this.setState({ queriedCircuitDesignObjects });
            }, this.props.handleFlashBarMessagesFromChildTabs
        );
    }

    FremontBackendClient = new FremontBackendClient();

    generateCircuitItems = () => {
        // In this stage, the static circuit items are used to display the selected
        // circuitDesigns that have been already been added to the order and the dynamic circuit items are used
        // to display the circuitDesigns that can be chosen and added to the order.
        // This stage is special because it does not have an edit and a display mode. Instead, both the static and
        // dynamic circuit items are modifiable through use of a checkbox column that indicates the circuitDesign
        // has been selected
        const searchForCircuitItemsObject = HelperFunctions.generateStageCircuitItems(
            this.state.selectedCircuitDesignObjects || [], // sometimes during a state change these are undefined
            this.state.queriedCircuitDesignObjects || [],
            false,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.allFieldsDisabled
        );

        // Here we generate a list of all the currently selected circuitDesignNumbers
        const selectedCircuitDesignNumbers = this.state.selectedCircuitDesignObjects.map(circuitDesign =>
            circuitDesign[Constants.ATTRIBUTES.circuitDesignNumber]);

        // Here we filter out all circuits that do not have a lifecycle stage of "Active" or have already been selected
        searchForCircuitItemsObject.dynamic = searchForCircuitItemsObject.dynamic.filter(circuitRowItem =>
            !selectedCircuitDesignNumbers.includes(circuitRowItem[Constants.ATTRIBUTES.circuitDesignNumber]) &&
            circuitRowItem[Constants.ATTRIBUTES.lifeCycleStage] === Constants.LIFECYCLE_STAGES.active);

        // Here we assign additional values that will be needed to display each circuit in our tables
        Object.keys(searchForCircuitItemsObject).forEach(itemArrayName =>
            searchForCircuitItemsObject[itemArrayName].forEach(circuitRowItem =>
                Object.assign(circuitRowItem, {
                    providerName: this.props.order[Constants.ATTRIBUTES.providerName],
                    handleStageInputChange: this.handleStageInputChange,
                    portBandwidth: HelperFunctions.getAttributeFromComponent(this.props.componentIdToObjectMap,
                        circuitRowItem.positionMap, Constants.COMPONENT_NAMES.portA, Constants.ATTRIBUTES.portBandwidth)
                })));

        return searchForCircuitItemsObject;
    };

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        // do not auto query circuits if orderType is RERATE/CTC or if Amazon Internal (Optical Team) provider
        if (!this.state.isEditClicked
            && ![Constants.ORDER_TYPES.RERATE, Constants.ORDER_TYPES.CTC].includes(this.props.order.orderType)
            && !HelperFunctions.isProviderAmazonInternal(this.props.order.providerName)) {
            this.getQueriedCircuitDesignObjects();
        }

        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            isUpdateStageInProgress: false,
            hasBeenSubmittedOnce: false,
            updateCreateSpanDecomOrdersOnTrigger: this.props.order.createSpanDecomOrdersOnTrigger || false,
            decomNoteBody: this.props.order.createSpanDecomOrdersOnTrigger ? this.state.decomNoteBody : "",
            noteToCreateBody: ""
        });
    };

    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        const circuitObjects = this.generateCircuitItems();
        input.circuitDesignObjects = HelperFunctions.deepClone(circuitObjects.dynamic).concat(
            HelperFunctions.deepClone(circuitObjects.static)
        );
        const { attributeId, index } = CircuitDesignValidation.getIdsAndIndex(input);
        const circuitDesign = input.circuitDesignObjects[index];
        // There are two elements in the IDs of the circuit selection IDs. The first is the attributeId (either
        // add or remove the circuit) and the second in the uniqueId of the circuit. However, since we have a button
        // that adds and removes all the circuits, we need to explicity obtain this uniqueId to see if we must take
        // action on just one circuit or many
        const uniqueId = input.evt.target.id.split(Constants.SEPARATOR)[1];
        if (attributeId === Constants.ADD_CIRCUIT_TO_ORDER) {
            if (Constants.ALL_CIRCUITS === uniqueId) {
                this.setState({
                    selectedCircuitDesignObjects: [
                        ...this.state.selectedCircuitDesignObjects,
                        ...this.state.queriedCircuitDesignObjects
                    ]
                });
            } else {
                this.setState({
                    selectedCircuitDesignObjects: [
                        ...this.state.selectedCircuitDesignObjects,
                        circuitDesign
                    ]
                });
            }
        } else if (Constants.ALL_CIRCUITS === uniqueId) {
            // If we are not adding a circuit, then we are removing it. If we are removing all the circuits
            // then we set the list of selectedCircuits to an empty list
            this.setState({
                selectedCircuitDesignObjects: []
            });
        } else {
            // If we are removing a circuit and we are not removing all of them,
            // we figure out which circuit we want to remove
            this.setState({
                selectedCircuitDesignObjects: this.state.selectedCircuitDesignObjects.filter(circuitObject =>
                    circuitObject.circuitDesignId !== circuitDesign.circuitDesignId)
            });
        }
        this.setState({
            queriedCircuitDesignObjects: input.circuitDesignObjects
        });
    };

    /**
     * This helper function takes an order object and makes an updateOrder request to the backend
     */
    handleStageSubmit = async () => {
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            isUpdateStageInProgress: true,
            hasBeenSubmittedOnce: true
        });
        if (this.state.lacpProviderError) {
            this.setState({
                isUpdateStageInProgress: false
            });
            return;
        }

        try {
            const updatedOrder = HelperFunctions.deepClone(this.props.order);
            updatedOrder.lacpProvider = HelperFunctions.booleanToString(this.state.lacpProvider);
            updatedOrder.createSpanDecomOrdersOnTrigger = this.state.updateCreateSpanDecomOrdersOnTrigger;

            const updatedCircuitDesignIdList = this.state.selectedCircuitDesignObjects
                .map(circuitDesign => circuitDesign.circuitDesignId);
            updatedOrder[Constants.FREMONT_OBJECTS.order.circuitDesignIdListToAddFromRequest] =
                updatedCircuitDesignIdList.filter(circuitDesignId =>
                    !updatedOrder.circuitDesignIdList.includes(circuitDesignId));
            updatedOrder[Constants.FREMONT_OBJECTS.order.circuitDesignIdListToRemoveFromRequest] =
                updatedOrder.circuitDesignIdList.filter(circuitDesignId =>
                    !updatedCircuitDesignIdList.includes(circuitDesignId));

            await this.FremontBackendClient.updateOrderInfo(updatedOrder, this.props.order, this.props.auth);
            // Here we call a helper function which updates all data related to the order
            this.props.loadData(true, true);
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    /**
     * This function handles changes to the orders lacp provider field
     */
    handleChangeOrderLacpProvider = (evt) => {
        const lacpProviderError = evt.detail.selectedOption.value !== null ? "" : Constants.ERROR_STRINGS.blankInput;
        this.setState({
            lacpProvider: evt.detail.selectedOption.value,
            lacpProviderError
        });
    }

    /**
     * This function handles change of inputs to the reason note field.
     */
    handleReasonInputChange = (evt) => {
        const noteToCreateBody = evt.detail.value;
        const errorText = HelperFunctions.validateInfo(noteToCreateBody,
            Constants.VALIDATE_INFO_OPTIONS.required);
        this.setState({
            noteToCreateBody,
            errorText
        });
    };

    handleModalVisibilityHandler = () => {
        // if this handler is called from the toggle button
        // otherwise it can also be called from the dismiss in the modal
        this.setState({
            updateCreateSpanDecomOrdersOnTrigger: !this.state.updateCreateSpanDecomOrdersOnTrigger
        });

        if (!this.state.updateCreateSpanDecomOrdersOnTrigger) {
            this.setState({
                isAddReasonClicked: !this.state.isAddReasonClicked
            });
        }
    }

    dismissNoteModal = () => {
        this.setState({
            isAddReasonClicked: false,
            updateCreateSpanDecomOrdersOnTrigger: false,
            noteToCreateBody: ""
        });
    }

    /**
     * This function submits a "noteToCreateBody" request to the backend.
     */
    handleAddNoteSubmit = async () => {
        const errorText = HelperFunctions.validateInfo(this.state.noteToCreateBody,
            Constants.VALIDATE_INFO_OPTIONS.required);
        if (errorText) {
            this.setState({
                errorText
            });
            return;
        }
        // This is used for dismissing the existing flashbar
        HelperFunctions.dismissFlashbar(this, {
            isAddReasonInProgress: true,
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true
        });

        // Create note
        try {
            const response = await this.FremontBackendClient.createNote([{
                entityType: Constants.NOTE_ENTITY_TYPES.order,
                entityId: this.props.order.orderId,
                noteBody: `${Constants.SPAN_DECOM_REASON} ${this.state.noteToCreateBody}`
            }], this.props.auth);
            this.setState({
                decomNoteBody: response.notes[0].noteBody,
                isAddReasonClicked: false,
                isAddReasonInProgress: false,
                isUpdateStageInProgress: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    searchParamsOptions = (orderType, customerFabric) => {
        if ([Constants.ORDER_TYPES.CTC, Constants.ORDER_TYPES.RERATE]
            .includes(orderType)) {
            return [];
        } else if (Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
            return Constants.SEARCH_PARAMETER_OPTIONS_PATH_DECOMCHANGE;
        }
        return Constants.SEARCH_PARAMETER_OPTIONS;
    }

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.selectCircuits}
                    showAttachmentModal={false}
                    disableEditButton={this.props.order.hasBeenSubmittedForApproval || this.props.editButtonsDisabled}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    content={
                        <div>
                            {HelperFunctions.isOrderPathOrder(this.props.order) &&
                                <SpanDecomToggle
                                    disabled
                                    orderType={this.props.order.orderType}
                                    orderId={this.props.order.orderId}
                                    checked={this.props.order.createSpanDecomOrdersOnTrigger}
                                    noteBody={this.state.decomNoteBody}
                                    expanded={
                                        !HelperFunctions.isStageCompleted(
                                            this.props.order.stageStatusMap[this.props.stageName]
                                        )
                                    }
                                />
                            }
                            <TableDisplayMode
                                order={this.props.order}
                                stageName={Constants.STAGE_NAMES.selectCircuits}
                                circuitItems={appendConsumedSpanProviderCircuitIdsToMeta(
                                    this.props.circuitDesignObjects, this.props.componentIdToObjectMap
                                )}
                                columnDefinitions={
                                    generateSelectCircuitsColumnDefinitions(
                                        this.props.order.serviceType, this.props.order.customerFabric
                                    )}
                                isDataLoaded={this.props.isDataLoaded}
                            />
                        </div>
                    }
                    showTemplateGeneratorModal={false}
                />
                :
                <div>
                    <StageEditMode
                        order={this.props.order}
                        stageName={Constants.STAGE_NAMES.selectCircuits}
                        handleStageEditClick={this.handleStageEditClick}
                        handleStageSubmit={this.handleStageSubmit}
                        isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                        content={
                            <SelectCircuitsStage
                                order={this.props.order}
                                createSpanDecomOrdersOnTrigger={this.state.updateCreateSpanDecomOrdersOnTrigger}
                                orderCompleted={this.props.orderCompleted}
                                queriedCircuitDesignObjects={this.state.queriedCircuitDesignObjects}
                                selectedCircuitDesignObjects={this.state.selectedCircuitDesignObjects}
                                handleStageInputChange={this.handleStageInputChange}
                                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                                lacpProvider={this.state.lacpProvider}
                                lacpProviderError={this.state.hasBeenSubmittedOnce && this.state.lacpProviderError}
                                handleChangeOrderLacpProvider={this.handleChangeOrderLacpProvider}
                                isQueryForCircuitsInProgress={this.state.isQueryForCircuitsInProgress}
                                circuitItems={this.generateCircuitItems()}
                                isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                                allFieldsDisabled={this.state.allFieldsDisabled}
                                handleModalVisibilityHandler={this.handleModalVisibilityHandler}
                                noteBody={this.state.decomNoteBody}
                                componentIdToObjectMap={this.props.componentIdToObjectMap}
                                searchInformation={
                                    <SearchInformation
                                        order={this.props.order}
                                        siteNames={this.props.siteNames}
                                        searchParameters={this.state.searchParameters}
                                        orderCompleted={this.props.orderCompleted}
                                        isUpdateInProgress={this.state.isUpdateStageInProgress}
                                        isQueryInProgress={this.state.isQueryForCircuitsInProgress}
                                        stageCompleted={false}
                                        setSearchParameters={(searchParameters) => {
                                            this.setState({ searchParameters });
                                        }}
                                        getQueriedObjects={this.getQueriedCircuitDesignObjects}
                                        handleFlashBarMessagesFromChildTabs={
                                            this.props.handleFlashBarMessagesFromChildTabs}
                                        disabledFilterFields={["Provider Name", "Service Type", "Site A Name",
                                            "Site Z Name", "Customer/Fabric", "LACP Provider", "Contract Central URL",
                                            "Span Number"]}
                                        disabledFilterInputs={["Provider Name", "Service Type", "Site A Name",
                                            "Site Z Name", "Customer/Fabric"]}
                                        disableSearchButton={this.state.orderCompleted
                                            || this.state.isUpdateStageInProgress}
                                        searchParameterOptions={this.searchParamsOptions(this.props.order.orderType,
                                            this.props.order.customerFabric)}
                                        customComponent={false}
                                    />
                                }
                            />
                        }
                    />
                    <OrderReasonNoteModal
                        isAddReasonClicked={this.state.isAddReasonClicked}
                        isAddReasonInProgress={this.state.isAddReasonInProgress}
                        handleReasonInputChange={this.handleReasonInputChange}
                        handleAddReasonSubmit={this.handleAddNoteSubmit}
                        errorText={this.state.errorText}
                        reasonBody={this.state.noteToCreateBody}
                        handleCloseReasonModal={this.dismissNoteModal}
                        popoverMessage="Span decom reason is mandatory before submit."
                        reasonLabel="Reason to decom spans:"
                        reasonHeader="Add a reason for creating the span decom orders."
                    />
                </div>
        );
    }
}