import React from "react";
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontBox,
    FremontButton,
    FremontDatePicker,
    FremontToggle,
    requiredLabel
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const ILAHardwareInstalledInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <FremontBox label="ILA RSPC Approved:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isILARSPCApproved] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="ILA RSPC Approved - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.ilaRSPCApprovedDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="ILA Hardware PO Created:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isILAHardwarePOCreated] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="ILA Hardware PO Created - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.ilaHardwarePOCreatedDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="ILA Hardware Delivered:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isILAHardwareDelivered] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="ILA Hardware Delivered - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.ilaHardwareDeliveredDate]}</div>
                    </div>
                    <div>
                        <FremontBox label="ILA Hardware Installed:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isILAHardwareInstalled] ===
                        Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                        </div>
                    </div>
                    <div>
                        <FremontBox label="ILA Hardware Installed - Actual Completion Date:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.ilaHardwareInstalledDate]}</div>
                    </div>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const ILAHardwareInstalledInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    ILA Hardware Installed
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        stretch
                        errorText={props.errorTexts.isILARSPCApproved}
                        label={requiredLabel("ILA RSPC Approved")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isILARSPCApproved}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isILARSPCApproved)}
                            checked={
                                props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.isILARSPCApproved]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("ILA RSPC Approved - Actual Completion Date:")}
                        errorText={props.errorTexts.ilaRSPCApprovedDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.ilaRSPCApprovedDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.ilaRSPCApprovedDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.ilaRSPCApprovedDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        stretch
                        errorText={props.errorTexts.isILAHardwarePOCreated}
                        label={requiredLabel("ILA Hardware PO Created")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isILAHardwarePOCreated}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isILAHardwarePOCreated)}
                            checked={
                                props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.isILAHardwarePOCreated]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("ILA Hardware PO Created - Actual Completion Date:")}
                        errorText={props.errorTexts.ilaHardwarePOCreatedDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.ilaHardwarePOCreatedDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.ilaHardwarePOCreatedDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.ilaHardwarePOCreatedDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        stretch
                        errorText={props.errorTexts.isILAHardwareDelivered}
                        label={requiredLabel("ILA Hardware Delivered")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isILAHardwareDelivered}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isILAHardwareDelivered)}
                            checked={
                                props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.isILAHardwareDelivered]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("ILA Hardware Delivered - Actual Completion Date:")}
                        errorText={props.errorTexts.ilaHardwareDeliveredDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.ilaHardwareDeliveredDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.ilaHardwareDeliveredDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.ilaHardwareDeliveredDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                    <FormField
                        stretch
                        errorText={props.errorTexts.isILAHardwareInstalled}
                        label={requiredLabel("ILA Hardware Installed")}
                    >
                        <FremontToggle
                            id={Constants.ATTRIBUTES.isILAHardwareInstalled}
                            disabled={props.isUpdateStageInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.isILAHardwareInstalled)}
                            checked={
                                props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.isILAHardwareInstalled]
                                === Constants.TRUE_STRING
                            }
                            onChange={props.handleStageInputChange}
                            description="Toggle to track completion"
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("ILA Hardware Installed - Actual Completion Date:")}
                        errorText={props.errorTexts.ilaHardwareInstalledDate}
                        stretch
                    >
                        <FremontDatePicker
                            id={Constants.ATTRIBUTES.ilaHardwareInstalledDate}
                            value={props.updatedOrder
                                .opticalMetaMap[Constants.ATTRIBUTES.ilaHardwareInstalledDate] || ""}
                            placeholder={Constants.DATE_PICKER_FORMAT}
                            disabled={props.isUpdateInProgress ||
                                props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.ilaHardwareInstalledDate)}
                            onInput={props.handleStageInputChange}
                            onChange={props.handleStageInputChange}
                        />
                    </FormField>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);