import React from "react";
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontBox,
    FremontButton,
    FremontInput,
    FremontDatePicker,
    FremontToggle,
    requiredLabel
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const LineFiberInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                <ColumnLayout
                    columns={props.order.projectType === Constants.PROJECT_TYPES.longHaulNewSpan ? 3 : 2}
                    borders="vertical"
                >
                    <div>
                        <FremontBox label="Fiber Handover MCM:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.fiberHandoverMcm]}</div>
                    </div>
                    {[Constants.PROJECT_TYPES.metroNewSpan, Constants.PROJECT_TYPES.longHaulNewSpan]
                        .includes(props.order.projectType) &&
                        <div>
                            <FremontBox label="Line Cutsheet MCM:"/>
                            <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.lineCutsheetMcm]}</div>
                        </div>
                    }
                    {Constants.PROJECT_TYPES.longHaulNewSpan === props.order.projectType && (
                        <React.Fragment>
                            <div>
                                <FremontBox label="ATP File:"/>
                                <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.atpFile]}</div>
                            </div>
                            <div>
                                <FremontBox label="Fiber Delivery:"/>
                                <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isFiberDelivered] ===
                                Constants.TRUE_STRING ? Constants.COMPLETE : Constants.INCOMPLETE}
                                </div>
                            </div>
                            <div>
                                <FremontBox label="Fiber Delivery - Estimated Completion Date:"/>
                                <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.estimatedFiberDeliveryDate]}</div>
                            </div>
                            <div>
                                <FremontBox label="Fiber Delivery - Actual Completion Date:"/>
                                <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.actualFiberDeliveryDate]}</div>
                            </div>
                        </React.Fragment>
                    )}
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const LineFiberInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Line Fiber
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout
                    columns={props.updatedOrder.projectType === Constants.PROJECT_TYPES.longHaulNewSpan ? 3 : 2}
                    borders="vertical"
                >
                    <FormField
                        label={requiredLabel("Fiber Handover MCM:")}
                        errorText={props.errorTexts.fiberHandoverMcm}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.fiberHandoverMcm}
                            disabled={props.isUpdateStageInProgress
                                || props.disabledFieldsList
                                    .includes(Constants.ATTRIBUTES.fiberHandoverMcm)}
                            value={props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.fiberHandoverMcm]}
                            onInput={props.handleStageInputChange}
                            placeholder="Enter Fiber Handover MCM"
                        />
                    </FormField>
                    {[Constants.PROJECT_TYPES.metroNewSpan, Constants.PROJECT_TYPES.longHaulNewSpan]
                        .includes(props.updatedOrder.projectType) &&
                        <FormField
                            label={requiredLabel("Line Cutsheet MCM:")}
                            errorText={props.errorTexts.lineCutsheetMcm}
                        >
                            <FremontInput
                                id={Constants.ATTRIBUTES.lineCutsheetMcm}
                                disabled={props.isUpdateStageInProgress
                                    || props.disabledFieldsList
                                        .includes(Constants.ATTRIBUTES.lineCutsheetMcm)}
                                value={props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.lineCutsheetMcm]}
                                onInput={props.handleStageInputChange}
                                placeholder="Enter Line Cutsheet MCM"
                            />
                        </FormField>
                    }
                    {Constants.PROJECT_TYPES.longHaulNewSpan === props.updatedOrder.projectType && (
                        <React.Fragment>
                            <FormField
                                label={requiredLabel("ATP File: ")}
                                errorText={props.errorTexts.atpFile}
                            >
                                <FremontInput
                                    id={Constants.ATTRIBUTES.atpFile}
                                    disabled={props.isUpdateInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.atpFile)}
                                    value={props.updatedOrder.opticalMetaMap[Constants.ATTRIBUTES.atpFile]}
                                    onInput={props.handleStageInputChange}
                                    placeholder="Enter ATP File storage folder URL"
                                />
                            </FormField>
                            <FormField
                                stretch
                                errorText={props.errorTexts.isFiberDelivered}
                                label={requiredLabel("Fiber Delivery")}
                            >
                                <FremontToggle
                                    id={Constants.ATTRIBUTES.isFiberDelivered}
                                    disabled={props.isUpdateStageInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.isFiberDelivered)}
                                    checked={
                                        props.updatedOrder.opticalMetaMap[
                                            Constants.ATTRIBUTES.isFiberDelivered
                                        ]
                                        === Constants.TRUE_STRING
                                    }
                                    onChange={props.handleStageInputChange}
                                    description="Toggle to track completion"
                                />
                            </FormField>
                            <FormField
                                label={requiredLabel("Fiber Delivery - Estimated Completion Date:")}
                                errorText={props.errorTexts.estimatedFiberDeliveryDate}
                                stretch
                            >
                                <FremontDatePicker
                                    id={Constants.ATTRIBUTES.estimatedFiberDeliveryDate}
                                    value={props.updatedOrder
                                        .opticalMetaMap[Constants.ATTRIBUTES.estimatedFiberDeliveryDate] || ""}
                                    placeholder={Constants.DATE_PICKER_FORMAT}
                                    disabled={props.isUpdateInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.estimatedFiberDeliveryDate)}
                                    onInput={props.handleStageInputChange}
                                    onChange={props.handleStageInputChange}
                                />
                            </FormField>
                            <FormField
                                label={requiredLabel("Fiber Delivery - Actual Completion Date:")}
                                stretch
                            >
                                <FremontDatePicker
                                    id={Constants.ATTRIBUTES.actualFiberDeliveryDate}
                                    value={props.updatedOrder
                                        .opticalMetaMap[Constants.ATTRIBUTES.actualFiberDeliveryDate] || ""}
                                    placeholder={Constants.DATE_PICKER_FORMAT}
                                    disabled={props.isUpdateInProgress ||
                                        props.disabledFieldsList
                                            .includes(Constants.ATTRIBUTES.actualFiberDeliveryDate)}
                                    onInput={props.handleStageInputChange}
                                    onChange={props.handleStageInputChange}
                                />
                            </FormField>
                        </React.Fragment>
                    )}
                </ColumnLayout>
            </div>
        </Container>
    </div>
);
